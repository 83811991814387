export const DsDialogOverrides = {
    MuiDialog: {
        styleOverrides: {
            paper: {
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                borderRadius: 'var(--ds-radius-bitterCold)',
                boxShadow: 'var(--ds-elevation-24)',
                margin: 'var(--ds-spacing-glacial)',
                backgroundImage: 'none',
                '& .MuiDialogContent-root': {
                    '&:has(.MuiCalendarOrClockPicker-root) + .MuiDialogActions-root': {
                        padding: 'var(--ds-spacing-bitterCold)'
                    }
                }
            },
            paperWidthMd: {
                maxWidth: 'var(--ds-rules-dialogMdMaxWidth)'
            }
        }
    }
};
