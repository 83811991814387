import React, { lazy, PureComponent, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import {
  DsBox,
  DsIconButton,
  DsImage,
  DsStack,
  IwithBreakpoints
} from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'
import withRouter, { IWithRouterProps } from '../Hocs/withRouter'

import Loader from '../Components/Loader'
import MobileNavbar from '../Components/MobileNavbar'

import { BANNER_IMAGE, MOBILE_BANNER_IMAGE } from '../Assets/GET_STARTED'

import APP_ROUTES, { BASE_PATHS } from '../Constants/APP_ROUTES'
import {
  COMPONENT_DEFAULT_WIDTH,
  DEFAULT_GET_STARTED_WIDTH
} from '../Constants/THEME'

const Navbar = lazy(() => import('../Components/Navbar'))

interface IMainLayoutProps
  extends IwithBreakpoints,
    IWithDeviceDetails,
    IWithRouterProps {}

class MainLayout extends PureComponent<IMainLayoutProps> {
  constructor(props: IMainLayoutProps) {
    super(props)
  }
  getWidthBasedOnRoute() {
    const { pathname } = this.props.location
    const corePath = pathname.startsWith('/') ? pathname.split('/')[1] : ''

    switch (corePath) {
      case BASE_PATHS.GET_STARTED:
        return DEFAULT_GET_STARTED_WIDTH
      case BASE_PATHS.FOUNDATIONS:
        if (pathname === APP_ROUTES.ELEVATION.pathname) {
          return DEFAULT_GET_STARTED_WIDTH
        } else if (
          pathname === APP_ROUTES.DESIGN_TOKENS.pathname ||
          pathname === APP_ROUTES.ACCESSIBILITY.pathname
        ) {
          return DEFAULT_GET_STARTED_WIDTH
        } else {
          return '100%'
        }
      case BASE_PATHS.COMPONENTS:
        return COMPONENT_DEFAULT_WIDTH
      default:
        return DEFAULT_GET_STARTED_WIDTH
    }
  }

  render() {
    const isMobile = this.props.isMobile || this.props.isTablet
    const dynamicWidth = this.getWidthBasedOnRoute()

    return (
      <DsBox sx={{ display: 'flex' }}>
        {!isMobile && (
          <Suspense fallback={<Loader />}>
            <DsBox width={'320px'}>
              <Navbar />
            </DsBox>
          </Suspense>
        )}
        <DsBox sx={{ width: { xs: '100%', lg: 'calc(100% - 320px)' } }}>
          {isMobile && (
            <MobileNavbar color='var(--ds-colour-surfaceBackground)' />
          )}
          <DsStack
            id='main-content'
            sx={{
              width: '100%',
              height: { xs: 'calc(100vh - 64px)', lg: '100vh' },
              scrollBehavior: 'smooth',
              overflowY: 'auto'
            }}
          >
            {isMobile ? (
              <DsIconButton disabled>
                <DsImage
                  srcSet={
                    this.props.isMobile ? MOBILE_BANNER_IMAGE : BANNER_IMAGE
                  }
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  aspectRatio={this.props.isMobile ? 103 / 70 : 24 / 7}
                ></DsImage>
              </DsIconButton>
            ) : (
              <DsBox
                sx={{
                  width: '100%',

                  /* The image used */
                  backgroundImage: `url(${BANNER_IMAGE[1].src})`,

                  /* Set a specific height */
                  minHeight: 'calc((100vw - 320px) * 7 / 24)',

                  /* Create the parallax scrolling effect */
                  backgroundAttachment: 'fixed',
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: '320px',
                  backgroundPositionY: '0px',
                  backgroundSize:
                    'calc(100vw - 320px) calc((100vw - 320px) * 7 / 24)'
                }}
              ></DsBox>
            )}

            <DsBox
              sx={{
                width: {
                  xs: '100%',
                  lg: dynamicWidth
                },
                p: {
                  lg: 0,
                  xs: 'var(--ds-spacing-bitterCold)'
                },
                pl: {
                  lg: 'var(--ds-spacing-superheated)'
                }
              }}
            >
              <Outlet />
            </DsBox>
          </DsStack>
        </DsBox>
      </DsBox>
    )
  }
}

export default withDeviceDetails(withRouter(MainLayout))
