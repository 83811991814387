export const DsPopoverOverrides = {
    MuiPopover: {
        styleOverrides: {
            root: {
                '.MuiBackdrop-root': {
                    backgroundColor: 'transparent'
                }
            }
        }
    }
};
