import React, { useState } from 'react'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsImage,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface ITextImage {
  src: string
  alt: string
  as: string
}
interface IGuideline {
  title: string
  description: string | JSX.Element
  image: ITextImage[]
}

interface IGuidelinesProps {
  guidelines: IGuideline[]
}

const ComponentGuidelines = ({ guidelines }: IGuidelinesProps) => {
  const [selectedGuideline, setSelectedGuideline] = useState<string>(
    guidelines[0].title
  )

  const handleClick = (title: string) => {
    setSelectedGuideline(title)
  }

  const description = guidelines.find(
    item => item.title === selectedGuideline
  )?.description

  return (
    <>
      <DsDivider />
      <DsBox
        sx={{
          width: '100%',
          mt: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))',
          borderRadius: 'var(--ds-radius-bitterCold)',
          border: '1px solid var(--ds-colour-strokeDefault)',
          display: 'flex',
          flexDirection: 'column',
          mb: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))',
          overflow: 'hidden'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 'var(--ds-spacing-mild)',
            gap: 'var(--ds-spacing-glacial)'
          }}
        >
          <DsRemixIcon
            className='ri-file-list-3-line'
            sx={{
              fontSize: '28px'
            }}
          />
          <DsTypography
            variant='headingBoldMedium'
            color='var(--ds-colour-typoPrimary)'
          >
            Guidelines
          </DsTypography>
        </DsBox>
        <DsDivider
          sx={{
            opacity: 0.6,
            width: '95%',
            mx: 'auto'
          }}
        />

        <DsGrid
          container
          sx={{
            width: '100%',
            padding: 'var(--ds-spacing-mild)'
          }}
        >
          <DsGrid
            item
            xs={5.5}
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-glacial)',
              flexDirection: 'column',
              pr: 'var(--ds-spacing-warm)'
            }}
          >
            {guidelines.map(item => (
              <DsTypography
                key={item.title}
                variant={
                  selectedGuideline === item.title
                    ? 'bodyBoldMedium'
                    : 'bodyRegularSmall'
                }
                color={
                  selectedGuideline === item.title
                    ? 'var(--ds-colour-actionSecondary)'
                    : 'none'
                }
                sx={{
                  borderRadius: 'var(--ds-radius-glacial)',
                  px: 'var(--ds-spacing-bitterCold)',
                  py: 'calc(var(--ds-spacing-glacial) + var(--ds-spacing-deepFreeze))',
                  backgroundColor:
                    selectedGuideline === item.title
                      ? 'var(--ds-colour-surfaceSecondary)'
                      : 'transparent',
                  cursor: 'pointer'
                }}
                onClick={() => handleClick(item.title)}
              >
                {item.title}
              </DsTypography>
            ))}
          </DsGrid>

          <DsGrid
            item
            xs={6.5}
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-glacial)',
              flexDirection: 'column'
            }}
          >
            {selectedGuideline && (
              <>
                <DsTypography variant='headingBoldMedium'>
                  {selectedGuideline}
                </DsTypography>
                <DsTypography variant='bodyRegularLarge'>
                  {description}
                </DsTypography>
              </>
            )}
          </DsGrid>
        </DsGrid>
        <DsBox>
          <DsImage
            width={'100%'}
            srcSet={
              guidelines.find(item => item.title === selectedGuideline)?.image
            }
          />
        </DsBox>
      </DsBox>
    </>
  )
}

export default ComponentGuidelines
