import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export function SingleDotLoader() {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1000 1000", preserveAspectRatio: "xMidYMid slice", style: {
            width: '100%',
            height: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
            contentVisibility: 'visible'
        }, children: [_jsxs("defs", { children: [_jsx("clipPath", { id: "__loader_element_11", children: _jsx("rect", { width: "1000", height: "1000", x: "0", y: "0" }) }), _jsx("style", { children: `@keyframes ball {
  0%{
    transform: matrix(2.428800106048584,0,0,2.428800106048584,491.30035400390625,444.7691650390625)
  }
  4%{
    transform: matrix(2.4268264770507812,0,0,2.428800106048584,491.308349609375,446.03826904296875)
  }
  8%{
    transform: matrix(2.420640230178833,0,0,2.428800106048584,491.3333435058594,450.0011901855469)
  }
  12%{
    transform: matrix(2.4097819328308105,0,0,2.428800106048584,491.37725830078125,456.9241638183594)
  }
  16%{
    transform: matrix(2.3936853408813477,0,0,2.428800106048584,491.44232177734375,467.1278991699219)
  }
  20%{
    transform: matrix(2.371640682220459,0,0,2.428800106048584,491.5314636230469,481.006103515625)
  }
  24%{
    transform: matrix(2.3427276611328125,0,0,2.428800106048584,491.6483459472656,499.0533142089844)
  }
  28%{
    transform: matrix(2.305706262588501,0,0,2.428800106048584,491.79803466796875,521.9090576171875)
  }
  32%{
    transform: matrix(2.2588188648223877,0,0,2.428800106048584,491.9875793457031,550.4329833984375)
  }
  36%{
    transform: matrix(2.199368715286255,0,0,2.428800106048584,492.2279357910156,585.8424682617188)
  }
  40%{
    transform: matrix(2.1226511001586914,0,0,2.428800106048584,492.5381164550781,630.0007934570312)
  }
  44%{
    transform: matrix(2.0179288387298584,0,0,2.428800106048584,492.9615173339844,686.14697265625)
  }
  48%{
    transform: matrix(2.185920238494873,0,0,2.185920238494873,492.2823181152344,762.3750610351562)
  }
  52%{
    transform: matrix(2.185920238494873,0,0,2.185920238494873,492.2823181152344,762.3750610351562)
  }
  56%{
    transform: matrix(2.0179288387298584,0,0,2.428800106048584,492.9615173339844,686.14697265625)
  }
  60%{
    transform: matrix(2.1226511001586914,0,0,2.428800106048584,492.5381164550781,630.0007934570312)
  }
  64%{
    transform: matrix(2.199368715286255,0,0,2.428800106048584,492.2279357910156,585.8424682617188)
  }
  68%{
    transform: matrix(2.2588188648223877,0,0,2.428800106048584,491.9875793457031,550.4329833984375)
  }
  72%{
    transform: matrix(2.305706262588501,0,0,2.428800106048584,491.79803466796875,521.9090576171875)
  }
  76%{
    transform: matrix(2.3427276611328125,0,0,2.428800106048584,491.6483459472656,499.0533142089844)
  }
  80%{
    transform: matrix(2.371640682220459,0,0,2.428800106048584,491.5314636230469,481.006103515625)
  }
  84%{
    transform: matrix(2.3936853408813477,0,0,2.428800106048584,491.44232177734375,467.1278991699219)
  }
  88%{
    transform: matrix(2.4097819328308105,0,0,2.428800106048584,491.37725830078125,456.9241638183594)
  }
  92%{
    transform: matrix(2.420640230178833,0,0,2.428800106048584,491.3333435058594,450.0011901855469)
  }
  96%{
    transform: matrix(2.4268264770507812,0,0,2.428800106048584,491.308349609375,446.03826904296875)
  }
  100%{
    transform: matrix(2.428800106048584,0,0,2.428800106048584,491.30035400390625,444.7691650390625)
  }
}
@keyframes shadow {
  0%{
    transform: matrix(2.3200900554656982,0,0,1.152750015258789,491.7398681640625,911.4684448242188)
  }
  4%{
    transform: matrix(2.312131881713867,0,0,1.1487959623336792,491.77203369140625,911.1839599609375)
  }
  8%{
    transform: matrix(2.2872824668884277,0,0,1.1364493370056152,491.87249755859375,910.2955322265625)
  }
  12%{
    transform: matrix(2.2438719272613525,0,0,1.1148805618286133,492.04803466796875,908.7434692382812)
  }
  16%{
    transform: matrix(2.17988920211792,0,0,1.0830904245376587,492.30670166015625,906.4559326171875)
  }
  20%{
    transform: matrix(2.0928657054901123,0,0,1.039852261543274,492.6585388183594,903.3446655273438)
  }
  24%{
    transform: matrix(1.9797005653381348,0,0,0.983625590801239,493.1160583496094,899.2987670898438)
  }
  28%{
    transform: matrix(1.8363832235336304,0,0,0.9124175310134888,493.69549560546875,894.1748657226562)
  }
  32%{
    transform: matrix(1.65752375125885,0,0,0.8235501646995544,494.41864013671875,887.7802124023438)
  }
  36%{
    transform: matrix(1.4354881048202515,0,0,0.7132304906845093,495.3163146972656,879.8419189453125)
  }
  40%{
    transform: matrix(1.158592939376831,0,0,0.5756535530090332,496.435791015625,869.9423217773438)
  }
  44%{
    transform: matrix(0.8065273761749268,0,0,0.400727778673172,497.85919189453125,857.3551635742188)
  }
  48%{
    transform: matrix(0.33317291736602783,0,0,0.16553886234760284,499.7729797363281,840.4317016601562)
  }
  52%{
    transform: matrix(0.33317291736602783,0,0,0.16553886234760284,499.7729797363281,840.4317016601562)
  }
  56%{
    transform: matrix(0.8065273761749268,0,0,0.400727778673172,497.85919189453125,857.3551635742188)
  }
  60%{
    transform: matrix(1.158592939376831,0,0,0.5756535530090332,496.435791015625,869.9423217773438)
  }
  64%{
    transform: matrix(1.4354881048202515,0,0,0.7132304906845093,495.3163146972656,879.8419189453125)
  }
  68%{
    transform: matrix(1.65752375125885,0,0,0.8235501646995544,494.41864013671875,887.7802124023438)
  }
  72%{
    transform: matrix(1.8363832235336304,0,0,0.9124175310134888,493.69549560546875,894.1748657226562)
  }
  76%{
    transform: matrix(1.9797005653381348,0,0,0.983625590801239,493.1160583496094,899.2987670898438)
  }
  80%{
    transform: matrix(2.0928657054901123,0,0,1.039852261543274,492.6585388183594,903.3446655273438)
  }
  84%{
    transform: matrix(2.17988920211792,0,0,1.0830904245376587,492.30670166015625,906.4559326171875)
  }
  88%{
    transform: matrix(2.2438719272613525,0,0,1.1148805618286133,492.04803466796875,908.7434692382812)
  }
  92%{
    transform: matrix(2.2872824668884277,0,0,1.1364493370056152,491.87249755859375,910.2955322265625)
  }
  96%{
    transform: matrix(2.312131881713867,0,0,1.1487959623336792,491.77203369140625,911.1839599609375)
  }
  100%{
    transform: matrix(
      2.3200900554656982,
      0,
      0,
      1.152750015258789,
      491.7398681640625,
      911.4684448242188
    );
  }
}` })] }), _jsxs("g", { "clip-path": "url(#__loader_element_11)", children: [_jsx("g", { transform: "matrix(2.3200900554656982,0,0,1.152750015258789,491.7398681640625,911.4684448242188)", opacity: "0.2", style: {
                            animation: 'shadow 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.043000221252441,-71.95700073242188)", children: _jsx("path", { fill: "currentColor", "fill-opacity": "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(2.428800106048584,0,0,2.428800106048584,491.30035400390625,444.7691650390625)", opacity: "1", style: {
                            animation: 'ball 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.043000221252441,-71.95700073242188)", children: _jsx("path", { fill: "currentColor", "fill-opacity": "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) })] })] }));
}
