import { DsDividerDefaultProps } from './DsDivider.Types';
export const DsDividerOverrides = {
    MuiDivider: {
        defaultProps: DsDividerDefaultProps,
        styleOverrides: {
            root: {
                borderColor: 'var(--ds-colour-strokeDefault)'
            },
            light: {
                borderColor: 'var(--ds-colour-strokeDisabled)'
            }
        },
        variants: [
            {
                props: { 'ds-size': 'M' },
                style: {
                    '&::before': {
                        borderWidth: '0.5px'
                    },
                    '&::after': {
                        borderWidth: '0.5px'
                    }
                }
            },
            {
                props: { 'ds-size': 'L' },
                style: {
                    '&::before': {
                        borderWidth: '6px'
                    },
                    '&::after': {
                        borderWidth: '6px'
                    }
                }
            }
        ]
    }
};
