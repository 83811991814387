import { To } from 'react-router-dom'

import APP_ROUTES from './APP_ROUTES'

interface IThemeOption {
  icon: string
  label: string
  scheme: 'light' | 'dark' | 'highContrast'
}
export interface INavBarItem {
  label: string
  icon?: string
  children?: INavBarItem[]
  link?: To
}

// const sortAlphabetically = (list: INavBarItem[]): INavBarItem[] => {
//   list.sort(function (a, b) {
//     const nameA = a.label.toLowerCase(),
//       nameB = b.label.toLowerCase()
//     if (nameA < nameB) return -1
//     if (nameA > nameB) return 1
//     return 0
//   })
//   return list
// }

// const COMPONENT_NAV_LIST: INavBarItem[] = [
//   { label: 'Accordion', link: APP_ROUTES.ACCORDION.pathname },
//   { label: 'Add Item', link: APP_ROUTES.ADD_ITEM.pathname },
//   { label: 'App Bar', link: APP_ROUTES.APP_BAR.pathname },
//   { label: 'Avatar', link: APP_ROUTES.AVATAR.pathname },
//   { label: 'Badge', link: APP_ROUTES.BADGE.pathname },
//   {
//     label: 'Bottom Navigation',
//     link: APP_ROUTES.BOTTOM_NAVIGATION.pathname
//   },
//   { label: 'Bottom Sheet', link: APP_ROUTES.BOTTOM_SHEET.pathname },
//   {
//     label: 'Breadcrumb',
//     link: APP_ROUTES.BREADCRUMB.pathname
//   },
//   { label: 'Button', link: APP_ROUTES.BUTTON.pathname },
//   { label: 'Carousel', link: APP_ROUTES.CAROUSEL.pathname },
//   { label: 'Checkbox', link: APP_ROUTES.CHECKBOX.pathname },
//   { label: 'Chip', link: APP_ROUTES.CHIP.pathname },
//   { label: 'Coachmark', link: APP_ROUTES.COACHMARK.pathname },
//   { label: 'Divider', link: APP_ROUTES.DIVIDER.pathname },
//   { label: 'Dialog', link: APP_ROUTES.DIALOG.pathname },
//   { label: 'Floating Action Button', link: APP_ROUTES.FAB.pathname },
//   { label: 'Header', link: APP_ROUTES.HEADER.pathname },
//   { label: 'Input Field', link: APP_ROUTES.INPUT_FIELD.pathname },
//   { label: 'Link', link: APP_ROUTES.LINK.pathname },
//   { label: 'Menu', link: APP_ROUTES.MENU.pathname },
//   { label: 'Onboarding', link: APP_ROUTES.ONBOARDING.pathname },
//   { label: 'Radio Button', link: APP_ROUTES.RADIO_BUTTON.pathname },
//   { label: 'Search', link: APP_ROUTES.SEARCH.pathname },
//   { label: 'Slider', link: APP_ROUTES.SLIDER.pathname },
//   { label: 'Switch', link: APP_ROUTES.SWITCH.pathname },
//   { label: 'Tabs', link: APP_ROUTES.TABS.pathname },
//   { label: 'Tags', link: APP_ROUTES.TAGS.pathname },
//   { label: 'Toast', link: APP_ROUTES.TOAST.pathname },
//   { label: 'Toggle', link: APP_ROUTES.TOGGLE.pathname },
//   { label: 'Tooltip', link: APP_ROUTES.TOOLTIP.pathname }
// ]

export const NAVBAR: INavBarItem[] = [
  {
    label: 'Home',
    icon: 'home',
    link: APP_ROUTES.HOME.pathname
  },
  {
    label: 'Get Started',
    icon: 'ri-compass-discover-line',
    children: [
      {
        label: 'About Subzero',
        icon: 'ri-star-line',
        link: APP_ROUTES.ABOUT_SUBZERO.pathname
      },
      {
        label: 'Designers Toolkit',
        icon: 'ri-pencil-ruler-2-line',
        link: APP_ROUTES.DESIGNERS_TOOLKIT.pathname
      },
      {
        label: 'Developers Toolkit',
        icon: 'ri-code-line',
        link: APP_ROUTES.DEVELOPERS_TOOLKIT.pathname
      },
      {
        label: 'Feedback',
        icon: 'ri-chat-smile-line',
        link: APP_ROUTES.FEEDBACK.pathname
      }
    ]
  },
  {
    label: 'Foundations',
    icon: 'ri-book-line',
    children: [
      {
        label: 'Technical Specs',
        children: [
          {
            label: 'Design Tokens',
            icon: 'ri-pantone-line',
            link: APP_ROUTES.DESIGN_TOKENS.pathname
          },
          {
            label: 'Accessibility',
            icon: 'ri-eye-line',
            link: APP_ROUTES.ACCESSIBILITY.pathname
          }
        ]
      },
      {
        label: 'Styling',
        children: [
          {
            label: 'Typography',
            icon: 'ri-text',
            link: APP_ROUTES.TYPOGRAPHY.pathname
          },
          {
            label: 'Colour',
            icon: 'ri-paint-line',
            link: APP_ROUTES.COLOURS.pathname
          },
          {
            label: 'Elevation',
            icon: 'ri-folders-line',
            link: APP_ROUTES.ELEVATION.pathname
          },
          {
            label: 'Iconography',
            icon: 'ri-user-smile-line',
            link: APP_ROUTES.ICONOGRAPHY.pathname
          },
          {
            label: 'Layout & Spacing',
            icon: 'ri-ruler-line',
            link: APP_ROUTES.LAYOUT_SPACING.pathname
          },
          {
            label: 'Data Visualisation',
            icon: 'ri-pie-chart-2-line',
            link: APP_ROUTES.DATA_VISUALISATION.pathname
          }
        ]
      }
    ]
  }
  // {
  //   label: 'Components',
  //   icon: 'ri-layout-4-line',
  //   children: sortAlphabetically(COMPONENT_NAV_LIST)
  // }
]
export const themeOptions: IThemeOption[] = [
  {
    icon: 'ri-sun-fill',
    label: 'Light',
    scheme: 'light'
  },
  {
    icon: 'ri-moon-fill',
    label: 'Dark',
    scheme: 'dark'
  },
  {
    icon: 'ri-eye-2-fill',
    label: 'High Contrast',
    scheme: 'highContrast'
  }
]

export const NAVBAR_WIDTH = '112px'

export const SUBNAVBAR_WIDTH = '208px'
