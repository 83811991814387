import React from 'react'

interface ISubZeroIconProps {
  color: string
}

const SubZeroIcon = ({ color }: ISubZeroIconProps) => {
  return (
    <div>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.3433 0.992454C16.5832 2.07252 18.3404 3.94844 19.2719 6.25415C19.7535 7.43999 20.0008 8.70791 20 9.98781C20.003 12.4746 19.0794 14.8733 17.4093 16.7158C15.7393 18.5583 13.4426 19.7125 10.9675 19.9531C8.4924 20.1937 6.01644 19.5034 4.0228 18.017C2.02914 16.5306 0.660854 14.3547 0.184898 11.9139C-0.291058 9.47315 0.159479 6.94258 1.44861 4.81605C2.73774 2.68951 4.77295 1.11959 7.15712 0.412625C9.54128 -0.294338 12.1033 -0.0876158 14.3433 0.992454ZM11.8544 9.74824L12.402 13.597L9.46871 16.5357C10.7157 16.6317 11.9684 16.3822 13.083 15.8112C14.3305 15.172 15.3417 14.1626 15.9728 12.9261C16.604 11.6896 16.8231 10.289 16.599 8.92309C16.3749 7.55718 15.719 6.29543 14.7245 5.31698C14.4798 5.07531 14.2162 4.85294 13.9361 4.65192L13.8757 4.62797L8.23585 10.2678L7.68824 6.41906L10.6663 3.43823C9.72909 3.34829 8.78197 3.45199 7.886 3.74392C6.89636 4.06638 5.99603 4.60975 5.25546 5.3315C4.0002 6.56621 3.29498 8.24082 3.29498 9.98695C3.29498 11.7331 4.0002 13.4077 5.25546 14.6424C5.50153 14.8826 5.765 15.1048 6.04381 15.3075L6.22389 15.3787L11.8544 9.74824Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default SubZeroIcon
