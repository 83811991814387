import React, { Suspense } from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

import HomePageLayout from '../Layouts/HomePage.Layout'
import MainLayout from '../Layouts/Main.Layout'

import Loader from '../Components/Loader'
import ScrollToTop from '~/src/Components/ScrollToTop'

import CheckboxPage from '../Pages/Components/Checkbox/Checkbox.Page'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

const HomePage = React.lazy(() => import('~/src/Pages/Home/Home.Page'))
const AboutSubzero = React.lazy(
  () => import('~/src/Pages/GettingStarted/AboutSubzero/AboutSubzero.Page')
)
const DesignersToolkit = React.lazy(
  () =>
    import('~/src/Pages/GettingStarted/DesignersToolkit/DesignersToolkit.Page')
)
const Feedback = React.lazy(
  () => import('~/src/Pages/GettingStarted/Feedback/Feedback.Page')
)
const Colour = React.lazy(
  () => import('~/src/Pages/Foundations/Colours/Colour.Page')
)
const Elevation = React.lazy(
  () => import('~/src/Pages/Foundations/Elevation/Elevation.Page')
)
const Iconography = React.lazy(
  () => import('~/src/Pages/Foundations/Iconography/Iconography.Page')
)
const DeveloperToolKitPage = React.lazy(
  () =>
    import('~/src/Pages/GettingStarted/DeveloperToolKit/DeveloperToolKit.Page')
)
const NotFoundPage = React.lazy(
  () => import('~/src/Pages/NotFound/NotFound.Page')
)

const V2Page = React.lazy(() => import('~/src/Pages/V2/V2.Page'))
const TypographyPage = React.lazy(
  () => import('~/src/Pages/Foundations/Typography/Typography.Page')
)
const LayoutAndSpacingPage = React.lazy(
  () => import('~/src/Pages/Foundations/LayoutAndSpacing/LayoutAndSpacing.Page')
)
const DataVisualisationPage = React.lazy(
  () =>
    import('~/src/Pages/Foundations/DataVisualisation/DataVisualisation.Page')
)
const DesignTokensPage = React.lazy(
  () => import('~/src/Pages/Foundations/DesignTokens/DesignTokens.Page')
)
const AccessibilityPage = React.lazy(
  () => import('~/src/Pages/Foundations/Accessibility/Accessibility.Page')
)
const ButtonPage = React.lazy(
  () => import('~/src/Pages/Components/Button/Button.Page')
)
const AccordionPage = React.lazy(
  () => import('~/src/Pages/Components/Accordion/Accordion.Page')
)
const AppBarPage = React.lazy(
  () => import('~/src/Pages/Components/AppBar/AppBar.Page')
)
const BadgePage = React.lazy(
  () => import('~/src/Pages/Components/Badge/Badge.Page')
)
const BottomNavigationPage = React.lazy(
  () => import('~/src/Pages/Components/BottomNavigation/BottomNavigation.Page')
)
const BreadcrumbPage = React.lazy(
  () => import('~/src/Pages/Components/Breadcrumb/Breadcrumb.Page')
)

const AvatarPage = React.lazy(
  () => import('~/src/Pages/Components/Avatar/Avatar.Page')
)

const ChipPage = React.lazy(
  () => import('~/src/Pages/Components/Chip/Chip.Page')
)

const DialogPage = React.lazy(
  () => import('~/src/Pages/Components/Dialog/Dialog.Page')
)

const TogglePage = React.lazy(
  () => import('~/src/Pages/Components/Toggle/Toggle.Page')
)

const BottomSheetPage = React.lazy(
  () => import('~/src/Pages/Components/BottomSheet/BottomSheet.Page')
)

const InputField = React.lazy(
  () => import('~/src/Pages/Components/InputField/InputField.Page')
)

const TagsPage = React.lazy(
  () => import('~/src/Pages/Components/Tags/Tags.Page')
)

const CarouselPage = React.lazy(
  () => import('~/src/Pages/Components/Carousel/Carousel.Page')
)

const OnboardingPage = React.lazy(
  () => import('~/src/Pages/Components/Onboarding/Onboarding.Page')
)

const MenuPage = React.lazy(
  () => import('~/src/Pages/Components/Menu/Menu.Page')
)

const FloatingActionButtonPage = React.lazy(
  () =>
    import(
      '~/src/Pages/Components/FloatingActionButton/FloatingActionButton.Page'
    )
)

const CoachmarkPage = React.lazy(
  () => import('~/src/Pages/Components/Coachmark/Coachmark.Page')
)

const RadioButtonPage = React.lazy(
  () => import('~/src/Pages/Components/RadioButton/RadioButton.Page')
)

const AddItemPage = React.lazy(
  () => import('~/src/Pages/Components/AddItem/AddItem.Page')
)

const SwitchPage = React.lazy(
  () => import('~/src/Pages/Components/Switch/Switch.Page')
)

const SearchPage = React.lazy(
  () => import('~/src/Pages/Components/Search/Search.Page')
)

const SliderPage = React.lazy(
  () => import('~/src/Pages/Components/Slider/Slider.Page')
)

const DividerPage = React.lazy(
  () => import('~/src/Pages/Components/Divider/Divider.Page')
)

const TabsPage = React.lazy(
  () => import('~/src/Pages/Components/Tabs/Tabs.Page')
)

const ToastPage = React.lazy(
  () => import('~/src/Pages/Components/Toast/Toast.Page')
)

const TooltipPage = React.lazy(
  () => import('~/src/Pages/Components/Tooltip/Tooltip.Page')
)

const HeaderPage = React.lazy(
  () => import('~/src/Pages/Components/Header/Header.Page')
)

const LinkPage = React.lazy(
  () => import('~/src/Pages/Components/Link/Link.Page')
)
// Suspense wrapper HOC that adds Suspense to any component
const SuspenseWrapper = (Component: React.ElementType) => {
  return (
    <Suspense fallback={<Loader />}>
      <>
        <ScrollToTop />
        <Component />
      </>
    </Suspense>
  )
}

const routeObj: RouteObject[] = [
  {
    element: <HomePageLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.pathname,
        element: <HomePage />
      }
    ]
  } as RouteObject,
  {
    element: <MainLayout />,
    children: [
      {
        path: APP_ROUTES.ABOUT_SUBZERO.pathname,
        element: SuspenseWrapper(AboutSubzero)
      },
      {
        path: APP_ROUTES.DESIGNERS_TOOLKIT.pathname,
        element: SuspenseWrapper(DesignersToolkit)
      },
      {
        path: APP_ROUTES.DEVELOPERS_TOOLKIT.pathname,
        element: SuspenseWrapper(DeveloperToolKitPage)
      },
      {
        path: APP_ROUTES.FEEDBACK.pathname,
        element: SuspenseWrapper(Feedback)
      },
      {
        path: APP_ROUTES.ICONOGRAPHY.pathname,
        element: SuspenseWrapper(Iconography)
      },
      {
        path: APP_ROUTES.ELEVATION.pathname,
        element: SuspenseWrapper(Elevation)
      },
      {
        path: APP_ROUTES.COLOURS.pathname,
        element: SuspenseWrapper(Colour)
      },
      {
        path: APP_ROUTES.TYPOGRAPHY.pathname,
        element: SuspenseWrapper(TypographyPage)
      },
      {
        path: APP_ROUTES.LAYOUT_SPACING.pathname,
        element: SuspenseWrapper(LayoutAndSpacingPage)
      },
      {
        path: APP_ROUTES.DATA_VISUALISATION.pathname,
        element: SuspenseWrapper(DataVisualisationPage)
      },
      {
        path: APP_ROUTES.DESIGN_TOKENS.pathname,
        element: SuspenseWrapper(DesignTokensPage)
      },
      {
        path: APP_ROUTES.ACCORDION.pathname,
        element: SuspenseWrapper(AccordionPage)
      },
      {
        path: APP_ROUTES.ACCESSIBILITY.pathname,
        element: SuspenseWrapper(AccessibilityPage)
      },
      {
        path: APP_ROUTES.BUTTON.pathname,
        element: SuspenseWrapper(ButtonPage)
      },
      {
        path: APP_ROUTES.APP_BAR.pathname,
        element: SuspenseWrapper(AppBarPage)
      },
      {
        path: APP_ROUTES.BADGE.pathname,
        element: SuspenseWrapper(BadgePage)
      },
      {
        path: APP_ROUTES.BOTTOM_NAVIGATION.pathname,
        element: SuspenseWrapper(BottomNavigationPage)
      },
      {
        path: APP_ROUTES.BREADCRUMB.pathname,
        element: SuspenseWrapper(BreadcrumbPage)
      },
      {
        path: APP_ROUTES.AVATAR.pathname,
        element: SuspenseWrapper(AvatarPage)
      },
      {
        path: APP_ROUTES.CHIP.pathname,
        element: SuspenseWrapper(ChipPage)
      },
      {
        path: APP_ROUTES.DIALOG.pathname,
        element: SuspenseWrapper(DialogPage)
      },
      {
        path: APP_ROUTES.TOGGLE.pathname,
        element: SuspenseWrapper(TogglePage)
      },
      {
        path: APP_ROUTES.BOTTOM_SHEET.pathname,
        element: SuspenseWrapper(BottomSheetPage)
      },
      {
        path: APP_ROUTES.INPUT_FIELD.pathname,
        element: SuspenseWrapper(InputField)
      },
      {
        path: APP_ROUTES.TAGS.pathname,
        element: SuspenseWrapper(TagsPage)
      },
      {
        path: APP_ROUTES.CAROUSEL.pathname,
        element: SuspenseWrapper(CarouselPage)
      },
      {
        path: APP_ROUTES.FAB.pathname,
        element: SuspenseWrapper(FloatingActionButtonPage)
      },
      {
        path: APP_ROUTES.COACHMARK.pathname,
        element: SuspenseWrapper(CoachmarkPage)
      },
      {
        path: APP_ROUTES.ONBOARDING.pathname,
        element: SuspenseWrapper(OnboardingPage)
      },
      {
        path: APP_ROUTES.RADIO_BUTTON.pathname,
        element: SuspenseWrapper(RadioButtonPage)
      },
      {
        path: APP_ROUTES.ADD_ITEM.pathname,
        element: SuspenseWrapper(AddItemPage)
      },
      {
        path: APP_ROUTES.CHECKBOX.pathname,
        element: SuspenseWrapper(CheckboxPage)
      },
      {
        path: APP_ROUTES.SWITCH.pathname,
        element: SuspenseWrapper(SwitchPage)
      },
      {
        path: APP_ROUTES.SEARCH.pathname,
        element: SuspenseWrapper(SearchPage)
      },
      {
        path: APP_ROUTES.DIVIDER.pathname,
        element: SuspenseWrapper(DividerPage)
      },
      {
        path: APP_ROUTES.SLIDER.pathname,
        element: SuspenseWrapper(SliderPage)
      },
      {
        path: APP_ROUTES.MENU.pathname,
        element: SuspenseWrapper(MenuPage)
      },
      {
        path: APP_ROUTES.TABS.pathname,
        element: SuspenseWrapper(TabsPage)
      },
      {
        path: APP_ROUTES.TOAST.pathname,
        element: SuspenseWrapper(ToastPage)
      },
      {
        path: APP_ROUTES.TOOLTIP.pathname,
        element: SuspenseWrapper(TooltipPage)
      },
      {
        path: APP_ROUTES.HEADER.pathname,
        element: SuspenseWrapper(HeaderPage)
      },
      {
        path: APP_ROUTES.LINK.pathname,
        element: SuspenseWrapper(LinkPage)
      }
    ]
  } as RouteObject,
  {
    path: APP_ROUTES.V2.pathname,
    element: <V2Page />
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
