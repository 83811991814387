import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export function ThreeDotLoader() {
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1000 1000", preserveAspectRatio: "xMidYMid slice", style: {
            width: '100%',
            height: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
            contentVisibility: 'visible'
        }, children: [_jsxs("defs", { children: [_jsx("clipPath", { id: `__loader_element_524`, children: _jsx("rect", { width: "1000", height: "1000", x: "0", y: "0" }) }), _jsx("style", { children: `@keyframes ball1 {
  0%{
    transform: matrix(1.3200000524520874,0,0,1.3200000524520874,178.98719787597656,413.82720947265625)
  }
  4%{
    transform: matrix(1.318943977355957,0,0,1.3200000524520874,178.991455078125,414.81719970703125)
  }
  8%{
    transform: matrix(1.3155120611190796,0,0,1.3200000524520874,179.0053253173828,417.8927917480469)
  }
  12%{
    transform: matrix(1.309704065322876,0,0,1.3200000524520874,179.02879333496094,423.26519775390625)
  }
  16%{
    transform: matrix(1.300860047340393,0,0,1.3200000524520874,179.0645294189453,431.1852111816406)
  }
  20%{
    transform: matrix(1.2889801263809204,0,0,1.3200000524520874,179.11251831054688,441.9563903808594)
  }
  24%{
    transform: matrix(1.2732720375061035,0,0,1.3200000524520874,179.17596435546875,455.96160888671875)
  }
  28%{
    transform: matrix(1.2530760765075684,0,0,1.3200000524520874,179.25755310058594,473.702392578125)
  }
  32%{
    transform: matrix(1.22760009765625,0,0,1.3200000524520874,179.36048889160156,495.85198974609375)
  }
  36%{
    transform: matrix(1.1952601671218872,0,0,1.3200000524520874,179.4911346435547,523.3344116210938)
  }
  40%{
    transform: matrix(1.1536800861358643,0,0,1.3200000524520874,179.65911865234375,557.6148071289062)
  }
  44%{
    transform: matrix(1.096656084060669,0,0,1.3200000524520874,179.88949584960938,601.18798828125)
  }
  48%{
    transform: matrix(1.188264012336731,0,0,1.1877361536026,179.51939392089844,660.1848754882812)
  }
  52%{
    transform: matrix(1.223244071006775,0,0,1.1527560949325562,179.37808227539062,660.291259765625)
  }
  56%{
    transform: matrix(1.096656084060669,0,0,1.3200000524520874,179.88949584960938,601.18798828125)
  }
  60%{
    transform: matrix(1.1536800861358643,0,0,1.3200000524520874,179.65911865234375,557.6016235351562)
  }
  64%{
    transform: matrix(1.1952601671218872,0,0,1.3200000524520874,179.4911346435547,523.3344116210938)
  }
  68%{
    transform: matrix(1.22760009765625,0,0,1.3200000524520874,179.36048889160156,495.83880615234375)
  }
  72%{
    transform: matrix(1.2530760765075684,0,0,1.3200000524520874,179.25755310058594,473.702392578125)
  }
  76%{
    transform: matrix(1.2732720375061035,0,0,1.3200000524520874,179.17596435546875,455.96160888671875)
  }
  80%{
    transform: matrix(1.2889801263809204,0,0,1.3200000524520874,179.11251831054688,441.9563903808594)
  }
  84%{
    transform: matrix(1.300860047340393,0,0,1.3200000524520874,179.0645294189453,431.1983947753906)
  }
  88%{
    transform: matrix(1.309704065322876,0,0,1.3200000524520874,179.02879333496094,423.26519775390625)
  }
  92%{
    transform: matrix(1.3155120611190796,0,0,1.3200000524520874,179.0053253173828,417.8927917480469)
  }
  96%{
    transform: matrix(1.318943977355957,0,0,1.3200000524520874,178.991455078125,414.81719970703125)
  }
  100%{
    transform: matrix(1.3200000524520874,0,0,1.3200000524520874,178.98719787597656,413.82720947265625)
  }
}
@keyframes ball2 {
  0%{
    transform: matrix(1.3130040168762207,0,0,1.3200000524520874,495.8154602050781,420.281982421875)
  }
  4%{
    transform: matrix(1.3174920082092285,0,0,1.3200000524520874,495.7973327636719,416.0843811035156)
  }
  8%{
    transform: matrix(1.319736123085022,0,0,1.3200000524520874,495.78826904296875,414.0647888183594)
  }
  12%{
    transform: matrix(1.319736123085022,0,0,1.3200000524520874,495.78826904296875,414.0647888183594)
  }
  16%{
    transform: matrix(1.3174920082092285,0,0,1.3200000524520874,495.7973327636719,416.0843811035156)
  }
  20%{
    transform: matrix(1.3130040168762207,0,0,1.3200000524520874,495.8154602050781,420.281982421875)
  }
  24%{
    transform: matrix(1.3056120872497559,0,0,1.3200000524520874,495.8453369140625,426.89520263671875)
  }
  28%{
    transform: matrix(1.2953161001205444,0,0,1.3200000524520874,495.8869323730469,436.2012023925781)
  }
  32%{
    transform: matrix(1.2815879583358765,0,0,1.3200000524520874,495.9423828125,448.5299987792969)
  }
  36%{
    transform: matrix(1.263767957687378,0,0,1.3200000524520874,496.0143737792969,464.3435974121094)
  }
  40%{
    transform: matrix(1.2410639524459839,0,0,1.3200000524520874,496.1061096191406,484.1964111328125)
  }
  44%{
    transform: matrix(1.212420105934143,0,0,1.3200000524520874,496.2218322753906,508.8671875)
  }
  48%{
    transform: matrix(1.1758559942245483,0,0,1.3200000524520874,496.3695373535156,539.5308227539062)
  }
  52%{
    transform: matrix(1.1276761293411255,0,0,1.3200000524520874,496.564208984375,578.0352172851562)
  }
  56%{
    transform: matrix(1.0560001134872437,0,0,1.3200000524520874,496.853759765625,628.0104370117188)
  }
  60%{
    transform: matrix(1.3200000524520874,0,0,1.0560001134872437,495.7872009277344,701.9013671875)
  }
  64%{
    transform: matrix(1.0560001134872437,0,0,1.3200000524520874,496.853759765625,628.0104370117188)
  }
  68%{
    transform: matrix(1.1276761293411255,0,0,1.3200000524520874,496.564208984375,578.0484008789062)
  }
  72%{
    transform: matrix(1.1758559942245483,0,0,1.3200000524520874,496.3695373535156,539.5308227539062)
  }
  76%{
    transform: matrix(1.212420105934143,0,0,1.3200000524520874,496.2218322753906,508.8671875)
  }
  80%{
    transform: matrix(1.2410639524459839,0,0,1.3200000524520874,496.1061096191406,484.1964111328125)
  }
  84%{
    transform: matrix(1.263767957687378,0,0,1.3200000524520874,496.0143737792969,464.3435974121094)
  }
  88%{
    transform: matrix(1.2815879583358765,0,0,1.3200000524520874,495.9423828125,448.5431823730469)
  }
  92%{
    transform: matrix(1.2953161001205444,0,0,1.3200000524520874,495.8869323730469,436.2012023925781)
  }
  96%{
    transform: matrix(1.3056120872497559,0,0,1.3200000524520874,495.8453369140625,426.89520263671875)
  }
  100%{
    transform: matrix(1.3130040168762207,0,0,1.3200000524520874,495.8154602050781,420.281982421875)
  }
}
@keyframes ball3 {
  0%{
    transform: matrix(1.2889801263809204,0,0,1.3200000524520874,812.7125244140625,441.9563903808594)
  }
  4%{
    transform: matrix(1.300860047340393,0,0,1.3200000524520874,812.66455078125,431.1983947753906)
  }
  8%{
    transform: matrix(1.309704065322876,0,0,1.3200000524520874,812.6288452148438,423.26519775390625)
  }
  12%{
    transform: matrix(1.3155120611190796,0,0,1.3200000524520874,812.6053466796875,417.8927917480469)
  }
  16%{
    transform: matrix(1.318943977355957,0,0,1.3200000524520874,812.5914916992188,414.81719970703125)
  }
  20%{
    transform: matrix(1.3200000524520874,0,0,1.3200000524520874,812.5872192382812,413.82720947265625)
  }
  24%{
    transform: matrix(1.318943977355957,0,0,1.3200000524520874,812.5914916992188,414.81719970703125)
  }
  28%{
    transform: matrix(1.3155120611190796,0,0,1.3200000524520874,812.6053466796875,417.8927917480469)
  }
  32%{
    transform: matrix(1.309704065322876,0,0,1.3200000524520874,812.6288452148438,423.26519775390625)
  }
  36%{
    transform: matrix(1.300860047340393,0,0,1.3200000524520874,812.66455078125,431.1852111816406)
  }
  40%{
    transform: matrix(1.2889801263809204,0,0,1.3200000524520874,812.7125244140625,441.9563903808594)
  }
  44%{
    transform: matrix(1.2732720375061035,0,0,1.3200000524520874,812.7760009765625,455.96160888671875)
  }
  48%{
    transform: matrix(1.2530760765075684,0,0,1.3200000524520874,812.8576049804688,473.702392578125)
  }
  52%{
    transform: matrix(1.22760009765625,0,0,1.3200000524520874,812.9605102539062,495.85198974609375)
  }
  56%{
    transform: matrix(1.1952601671218872,0,0,1.3200000524520874,813.0911254882812,523.3344116210938)
  }
  60%{
    transform: matrix(1.1536800861358643,0,0,1.3200000524520874,813.2591552734375,557.6148071289062)
  }
  64%{
    transform: matrix(1.096656084060669,0,0,1.3200000524520874,813.489501953125,601.18798828125)
  }
  68%{
    transform: matrix(1.188264012336731,0,0,1.1877361536026,813.1194458007812,660.1848754882812)
  }
  72%{
    transform: matrix(1.223244071006775,0,0,1.1527560949325562,812.9780883789062,660.291259765625)
  }
  76%{
    transform: matrix(1.096656084060669,0,0,1.3200000524520874,813.489501953125,601.18798828125)
  }
  80%{
    transform: matrix(1.1536800861358643,0,0,1.3200000524520874,813.2591552734375,557.6016235351562)
  }
  84%{
    transform: matrix(1.1952601671218872,0,0,1.3200000524520874,813.0911254882812,523.3344116210938)
  }
  88%{
    transform: matrix(1.22760009765625,0,0,1.3200000524520874,812.9605102539062,495.83880615234375)
  }
  92%{
    transform: matrix(1.2530760765075684,0,0,1.3200000524520874,812.8576049804688,473.702392578125)
  }
  96%{
    transform: matrix(1.2732720375061035,0,0,1.3200000524520874,812.7760009765625,455.96160888671875)
  }
  100%{
    transform: matrix(1.2889801263809204,0,0,1.3200000524520874,812.7125244140625,441.9563903808594)
  }
}
@keyframes shadow1 {
  0%{
    transform: matrix(1.2540000677108765,0,0,0.6230400204658508,179.2538299560547,758.3539428710938)
  }
  4%{
    transform: matrix(1.2496440410614014,0,0,0.6209280490875244,179.2714385986328,758.2019653320312)
  }
  8%{
    transform: matrix(1.236180067062378,0,0,0.6141960024833679,179.32582092285156,757.717529296875)
  }
  12%{
    transform: matrix(1.2128159999847412,0,0,0.6025800704956055,179.42022705078125,756.8816528320312)
  }
  16%{
    transform: matrix(1.1782320737838745,0,0,0.5854200124740601,179.55992126464844,755.6468505859375)
  }
  20%{
    transform: matrix(1.131108045578003,0,0,0.5620560646057129,179.75030517578125,753.965576171875)
  }
  24%{
    transform: matrix(1.0699920654296875,0,0,0.5316960215568542,179.99722290039062,751.7808837890625)
  }
  28%{
    transform: matrix(0.992508053779602,0,0,0.49315202236175537,180.3102569580078,749.0072631835938)
  }
  32%{
    transform: matrix(0.895884096622467,0,0,0.44510403275489807,180.70062255859375,745.5496826171875)
  }
  36%{
    transform: matrix(0.7758960127830505,0,0,0.38544002175331116,181.1853790283203,741.2562866210938)
  }
  40%{
    transform: matrix(0.6262080073356628,0,0,0.3111239969730377,181.79010009765625,735.9085083007812)
  }
  44%{
    transform: matrix(0.43586403131484985,0,0,0.21661199629306793,182.55909729003906,729.107421875)
  }
  48%{
    transform: matrix(0.18004800379276276,0,0,0.08949600160121918,183.59259033203125,719.9601440429688)
  }
  52%{
    transform: matrix(0.18004800379276276,0,0,0.08949600160121918,183.59259033203125,719.9601440429688)
  }
  56%{
    transform: matrix(0.43586403131484985,0,0,0.21661199629306793,182.55909729003906,729.107421875)
  }
  60%{
    transform: matrix(0.6262080073356628,0,0,0.3111239969730377,181.79010009765625,735.9085083007812)
  }
  64%{
    transform: matrix(0.7758960127830505,0,0,0.38544002175331116,181.1853790283203,741.2562866210938)
  }
  68%{
    transform: matrix(0.895884096622467,0,0,0.44510403275489807,180.70062255859375,745.5496826171875)
  }
  72%{
    transform: matrix(0.992508053779602,0,0,0.49315202236175537,180.3102569580078,749.0072631835938)
  }
  76%{
    transform: matrix(1.0699920654296875,0,0,0.5316960215568542,179.99722290039062,751.7808837890625)
  }
  80%{
    transform: matrix(1.131108045578003,0,0,0.5620560646057129,179.75030517578125,753.965576171875)
  }
  84%{
    transform: matrix(1.1782320737838745,0,0,0.5854200124740601,179.55992126464844,755.6468505859375)
  }
  88%{
    transform: matrix(1.2128159999847412,0,0,0.6025800704956055,179.42022705078125,756.8816528320312)
  }
  92%{
    transform: matrix(1.236180067062378,0,0,0.6141960024833679,179.32582092285156,757.717529296875)
  }
  96%{
    transform: matrix(1.2496440410614014,0,0,0.6209280490875244,179.2714385986328,758.2019653320312)
  }
  100%{
    transform: matrix(1.2540000677108765,0,0,0.6230400204658508,179.2538299560547,758.3539428710938)
  }
}
@keyframes shadow2 {
  0%{
    transform: matrix(1.225356101989746,0,0,0.608784019947052,496.1695556640625,757.328125)
  }
  4%{
    transform: matrix(1.2435719966888428,0,0,0.6178920269012451,496.0959777832031,757.9835205078125)
  }
  8%{
    transform: matrix(1.2524160146713257,0,0,0.6222479939460754,496.06024169921875,758.2969360351562)
  }
  12%{
    transform: matrix(1.2524160146713257,0,0,0.6222479939460754,496.06024169921875,758.2969360351562)
  }
  16%{
    transform: matrix(1.2435719966888428,0,0,0.6178920269012451,496.0959777832031,757.9835205078125)
  }
  20%{
    transform: matrix(1.225356101989746,0,0,0.608784019947052,496.1695556640625,757.328125)
  }
  24%{
    transform: matrix(1.1964479684829712,0,0,0.5943959951400757,496.2863464355469,756.2927856445312)
  }
  28%{
    transform: matrix(1.155791997909546,0,0,0.5743319988250732,496.4505920410156,754.8489379882812)
  }
  32%{
    transform: matrix(1.1019361019134521,0,0,0.5475360155105591,496.6681823730469,752.9207153320312)
  }
  36%{
    transform: matrix(1.0330320596694946,0,0,0.5132160782814026,496.9465637207031,750.4510498046875)
  }
  40%{
    transform: matrix(0.9464399814605713,0,0,0.47018399834632874,497.29638671875,747.3544311523438)
  }
  44%{
    transform: matrix(0.8387280106544495,0,0,0.41672399640083313,497.7315368652344,743.5074462890625)
  }
  48%{
    transform: matrix(0.7050120234489441,0,0,0.3503280580043793,498.2717590332031,738.7296142578125)
  }
  52%{
    transform: matrix(0.53684401512146,0,0,0.26677200198173523,498.9511413574219,732.7169189453125)
  }
  56%{
    transform: matrix(0.31891199946403503,0,0,0.15839999914169312,499.83160400390625,724.91845703125)
  }
  60%{
    transform: matrix(0,0,0,0,501.1199951171875,713.52001953125)
  }
  64%{
    transform: matrix(0.31891199946403503,0,0,0.15839999914169312,499.83160400390625,724.91845703125)
  }
  68%{
    transform: matrix(0.53684401512146,0,0,0.26677200198173523,498.9511413574219,732.7169189453125)
  }
  72%{
    transform: matrix(0.7050120234489441,0,0,0.3503280580043793,498.2717590332031,738.7296142578125)
  }
  76%{
    transform: matrix(0.8387280106544495,0,0,0.41672399640083313,497.7315368652344,743.5074462890625)
  }
  80%{
    transform: matrix(0.9464399814605713,0,0,0.47018399834632874,497.29638671875,747.3544311523438)
  }
  84%{
    transform: matrix(1.0330320596694946,0,0,0.5132160782814026,496.9465637207031,750.4510498046875)
  }
  88%{
    transform: matrix(1.1019361019134521,0,0,0.5475360155105591,496.6681823730469,752.9207153320312)
  }
  92%{
    transform: matrix(1.155791997909546,0,0,0.5743319988250732,496.4505920410156,754.8489379882812)
  }
  96%{
    transform: matrix(1.1964479684829712,0,0,0.5943959951400757,496.2863464355469,756.2927856445312)
  }
  100%{
    transform: matrix(1.225356101989746,0,0,0.608784019947052,496.1695556640625,757.328125)
  }
}
@keyframes shadow3 {
  0%{
    transform: matrix(1.1307121515274048,0,0,0.5617920756340027,813.3519287109375,753.946533203125)
  }
  4%{
    transform: matrix(1.177704095840454,0,0,0.5851560235023499,813.162109375,755.6278686523438)
  }
  8%{
    transform: matrix(1.2122880220413208,0,0,0.6023160219192505,813.0223999023438,756.8626708984375)
  }
  12%{
    transform: matrix(1.2356520891189575,0,0,0.6139320135116577,812.927978515625,757.6985473632812)
  }
  16%{
    transform: matrix(1.249116063117981,0,0,0.6206640005111694,812.8735961914062,758.1829833984375)
  }
  20%{
    transform: matrix(1.2534719705581665,0,0,0.6227760314941406,812.85595703125,758.3349609375)
  }
  24%{
    transform: matrix(1.249116063117981,0,0,0.6206640005111694,812.8735961914062,758.1829833984375)
  }
  28%{
    transform: matrix(1.2356520891189575,0,0,0.6139320135116577,812.927978515625,757.6985473632812)
  }
  32%{
    transform: matrix(1.2122880220413208,0,0,0.6023160219192505,813.0223999023438,756.8626708984375)
  }
  36%{
    transform: matrix(1.177704095840454,0,0,0.5851560235023499,813.162109375,755.6278686523438)
  }
  40%{
    transform: matrix(1.1307121515274048,0,0,0.5617920756340027,813.3519287109375,753.946533203125)
  }
  44%{
    transform: matrix(1.0695960521697998,0,0,0.531432032585144,813.5988159179688,751.7618408203125)
  }
  48%{
    transform: matrix(0.9921121001243591,0,0,0.49288803339004517,813.911865234375,748.9882202148438)
  }
  52%{
    transform: matrix(0.8954880237579346,0,0,0.4449720084667206,814.30224609375,745.5402221679688)
  }
  56%{
    transform: matrix(0.7754999995231628,0,0,0.38530802726745605,814.7869873046875,741.2467651367188)
  }
  60%{
    transform: matrix(0.6259440183639526,0,0,0.3109920024871826,815.3911743164062,735.8989868164062)
  }
  64%{
    transform: matrix(0.43573197722435,0,0,0.21648000180721283,816.15966796875,729.097900390625)
  }
  68%{
    transform: matrix(0.18004800379276276,0,0,0.08949600160121918,817.192626953125,719.9601440429688)
  }
  72%{
    transform: matrix(0.18004800379276276,0,0,0.08949600160121918,817.192626953125,719.9601440429688)
  }
  76%{
    transform: matrix(0.43573197722435,0,0,0.21648000180721283,816.15966796875,729.097900390625)
  }
  80%{
    transform: matrix(0.6259440183639526,0,0,0.3109920024871826,815.3911743164062,735.8989868164062)
  }
  84%{
    transform: matrix(0.7754999995231628,0,0,0.38530802726745605,814.7869873046875,741.2467651367188)
  }
  88%{
    transform: matrix(0.8954880237579346,0,0,0.4449720084667206,814.30224609375,745.5402221679688)
  }
  92%{
    transform: matrix(0.9921121001243591,0,0,0.49288803339004517,813.911865234375,748.9882202148438)
  }
  96%{
    transform: matrix(1.0695960521697998,0,0,0.531432032585144,813.5988159179688,751.7618408203125)
  }
  100%{
    transform: matrix(1.1307121515274048,0,0,0.5617920756340027,813.3519287109375,753.946533203125)
  }
}
@keyframes ripple1 {
  0%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  4%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  8%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  12%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  16%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  20%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  24%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  28%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  32%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  36%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  40%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  44%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  48%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  52%{
    transform: matrix(0.5002800226211548,0,0,0.2485560029745102,182.29885864257812,731.4060668945312);
    opacity: 0.1149;
    display: block;
  }
  56%{
    transform: matrix(0.9051240086555481,0,0,0.449724018573761,180.6632843017578,745.8821411132812);
    opacity: 0.17800000000000002;
    display: block;
  }
  60%{
    transform: matrix(1.156715989112854,0,0,0.5747280120849609,179.6468505859375,754.87744140625);
    opacity: 0.198;
    display: block;
  }
  64%{
    transform: matrix(1.337820053100586,0,0,0.6647520065307617,178.91519165039062,761.3555908203125);
    opacity: 0.19820000000000002;
    display: block;
  }
  68%{
    transform: matrix(1.4749679565429688,0,0,0.7328640222549438,178.36111450195312,766.2568969726562);
    opacity: 0.18760000000000002;
    display: block;
  }
  72%{
    transform: matrix(1.5809640884399414,0,0,0.7855319976806641,177.93289184570312,770.046875);
    opacity: 0.17170000000000002;
    display: block;
  }
  76%{
    transform: matrix(1.6630680561065674,0,0,0.8263199925422668,177.6011962890625,772.9819946289062);
    opacity: 0.1524;
    display: block;
  }
  80%{
    transform: matrix(1.7262959480285645,0,0,0.8577361106872559,177.34576416015625,775.24267578125);
    opacity: 0.1307;
    display: block;
  }
  84%{
    transform: matrix(1.7738161087036133,0,0,0.8813640475273132,177.1537628173828,776.9429931640625);
    opacity: 0.1072;
    display: block;
  }
  88%{
    transform: matrix(1.808135986328125,0,0,0.8983920216560364,177.01512145996094,778.1683349609375);
    opacity: 0.08220000000000001;
    display: block;
  }
  92%{
    transform: matrix(1.8309721946716309,0,0,0.9097440242767334,176.92286682128906,778.9851684570312);
    opacity: 0.055999999999999994;
    display: block;
  }
  96%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: block;
  }
  100%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,176.87060546875,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
}
@keyframes ripple2 {
  0%{
    transform: matrix(1.8209398984909058,0,0,0.9047280550003052,493.7633972167969,778.6242065429688);
    opacity: 0.0693;
    display: block;
  }
  4%{
    transform: matrix(1.8386280536651611,0,0,0.9135720133781433,493.69195556640625,779.2606201171875);
    opacity: 0.0424;
    display: block;
  }
  8%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: block;
  }
  12%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  16%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  20%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  24%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  28%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  32%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  36%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  40%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  44%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  48%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  52%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  56%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  60%{
    transform: matrix(1.846943974494934,0,0,0.9176639914512634,493.6583557128906,779.5551147460938);
    opacity: 0.0144;
    display: none;
  }
  64%{
    transform: matrix(0.731544017791748,0,0,0.3635280430316925,498.16455078125,739.6795043945312);
    opacity: 0.1551;
    display: block;
  }
  68%{
    transform: matrix(1.0414800643920898,0,0,0.5174400210380554,496.91241455078125,750.7550048828125);
    opacity: 0.1912;
    display: block;
  }
  72%{
    transform: matrix(1.2534719705581665,0,0,0.6227760314941406,496.05596923828125,758.3349609375);
    opacity: 0.2;
    display: block;
  }
  76%{
    transform: matrix(1.4106841087341309,0,0,0.7009199857711792,495.42083740234375,763.9581909179688);
    opacity: 0.1938;
    display: block;
  }
  80%{
    transform: matrix(1.5312000513076782,0,0,0.7607160806655884,494.9339599609375,768.2611083984375);
    opacity: 0.1802;
    display: block;
  }
  84%{
    transform: matrix(1.6245241165161133,0,0,0.8071800470352173,494.5569152832031,771.6046752929688);
    opacity: 0.1624;
    display: block;
  }
  88%{
    transform: matrix(1.696727991104126,0,0,0.8430840373039246,494.2652282714844,774.1883544921875);
    opacity: 0.1418;
    display: block;
  }
  92%{
    transform: matrix(1.751772165298462,0,0,0.8704081177711487,494.0428466796875,776.154541015625);
    opacity: 0.1192;
    display: block;
  }
  96%{
    transform: matrix(1.792427897453308,0,0,0.8906040787696838,493.87860107421875,777.60791015625);
    opacity: 0.0949;
    display: block;
  }
  100%{
    transform: matrix(1.8209398984909058,0,0,0.9047280550003052,493.7633972167969,778.6242065429688);
    opacity: 0.0693;
    display: block;
  }
}
@keyframes ripple3 {
  0%{
    transform: matrix(1.7262959480285645,0,0,0.8577361106872559,810.94580078125,775.24267578125);
    opacity: 0.1307;
    display: block;
  }
  4%{
    transform: matrix(1.7738161087036133,0,0,0.8813640475273132,810.7537841796875,776.9429931640625);
    opacity: 0.1072;
    display: block;
  }
  8%{
    transform: matrix(1.808135986328125,0,0,0.8983920216560364,810.6151123046875,778.1683349609375);
    opacity: 0.08220000000000001;
    display: block;
  }
  12%{
    transform: matrix(1.8309721946716309,0,0,0.9097440242767334,810.5228881835938,778.9851684570312);
    opacity: 0.055999999999999994;
    display: block;
  }
  16%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: block;
  }
  20%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  24%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  28%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  32%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  36%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  40%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  44%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  48%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  52%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  56%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  60%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  64%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  68%{
    transform: matrix(1.8439081907272339,0,0,0.9162120819091797,810.4706420898438,779.4506225585938);
    opacity: 0.0286;
    display: none;
  }
  72%{
    transform: matrix(0.497376024723053,0,0,0.2471040040254593,815.9105834960938,731.3016357421875);
    opacity: 0.1149;
    display: block;
  }
  76%{
    transform: matrix(0.9038040637969971,0,0,0.4490640163421631,814.2686767578125,745.8346557617188);
    opacity: 0.17800000000000002;
    display: block;
  }
  80%{
    transform: matrix(1.1560560464859009,0,0,0.5743319988250732,813.24951171875,754.8489379882812);
    opacity: 0.198;
    display: block;
  }
  84%{
    transform: matrix(1.3374241590499878,0,0,0.6644880771636963,812.516845703125,761.3365478515625);
    opacity: 0.19820000000000002;
    display: block;
  }
  88%{
    transform: matrix(1.4748361110687256,0,0,0.7327319979667664,811.961669921875,766.2473754882812);
    opacity: 0.18760000000000002;
    display: block;
  }
  92%{
    transform: matrix(1.5808321237564087,0,0,0.7854000926017761,811.533447265625,770.0374145507812);
    opacity: 0.17170000000000002;
    display: block;
  }
  96%{
    transform: matrix(1.6630680561065674,0,0,0.8263199925422668,811.2012329101562,772.9819946289062);
    opacity: 0.1524;
    display: block;
  }
  100%{
    transform: matrix(1.7262959480285645,0,0,0.8577361106872559,810.94580078125,775.24267578125);
    opacity: 0.1307;
    display: block;
  }
}` })] }), _jsxs("g", { clipPath: `url(#__loader_element_524)`, children: [_jsx("g", { className: "shadow3", transform: "matrix(1.1307121515274048,0,0,0.5617920756340027,813.3519287109375,753.946533203125)", opacity: "0.2", style: {
                            display: 'block',
                            animation: 'shadow3 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.225356101989746,0,0,0.608784019947052,496.1695556640625,757.328125)", opacity: "0.2", style: {
                            display: 'block',
                            animation: 'shadow2 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.2540000677108765,0,0,0.6230400204658508,179.2538299560547,758.3539428710938)", opacity: "0.2", style: {
                            display: 'block',
                            animation: 'shadow1 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.7262959480285645,0,0,0.8577361106872559,810.94580078125,775.24267578125)", opacity: "0.1307", style: {
                            display: 'block',
                            animation: 'ripple3 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { strokeLinecap: "butt", strokeLinejoin: "miter", fillOpacity: "0", strokeMiterlimit: "4", stroke: "currentColor", strokeOpacity: "1", strokeWidth: "10", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.8209398984909058,0,0,0.9047280550003052,493.7633972167969,778.6242065429688)", opacity: "0.0693", style: {
                            display: 'block',
                            animation: 'ripple2 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { strokeLinecap: "butt", strokeLinejoin: "miter", fillOpacity: "0", strokeMiterlimit: "4", stroke: "currentColor", strokeOpacity: "1", strokeWidth: "10", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.8469324111938477,0,0,0.9176585078239441,176.8583984375,779.5546875)", opacity: "0.014482359999944013", style: {
                            animation: 'ripple1 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { strokeLinecap: "butt", strokeLinejoin: "miter", fillOpacity: "0", strokeMiterlimit: "4", stroke: "currentColor", strokeOpacity: "1", strokeWidth: "10", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { className: "ball3", transform: "matrix(1.2889801263809204,0,0,1.3200000524520874,812.7125244140625,441.9563903808594)", opacity: "1", style: {
                            display: 'block',
                            animation: 'ball3 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { className: "ball2", transform: "matrix(1.3130040168762207,0,0,1.3200000524520874,495.8154602050781,420.281982421875)", opacity: "1", style: {
                            display: 'block',
                            animation: 'ball2 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) }), _jsx("g", { transform: "matrix(1.3200000524520874,0,0,1.3200000524520874,178.98719787597656,413.82720947265625)", opacity: "1", style: {
                            display: 'block',
                            animation: 'ball1 834ms cubic-bezier(0.0, 0.0, 0.2, 1.0) infinite'
                        }, children: _jsx("g", { opacity: "1", transform: "matrix(1,0,0,1,4.039999961853027,-71.95999908447266)", children: _jsx("path", { fill: "currentColor", fillOpacity: "1", d: " M75,0 C75,0 75,0 75,0 C75,41.39250183105469 41.39250183105469,75 0,75 C0,75 0,75 0,75 C-41.39250183105469,75 -75,41.39250183105469 -75,0 C-75,0 -75,0 -75,0 C-75,-41.39250183105469 -41.39250183105469,-75 0,-75 C0,-75 0,-75 0,-75 C41.39250183105469,-75 75,-41.39250183105469 75,0z" }) }) })] })] }));
}
