import { ThreeDotLoader } from './ThreeDotLoader';
import { SingleDotLoader } from './SingleDotLoader';
export const DS_LOADER_DEFAULT_PROPS = {
    'ds-variant': 'threeDot',
    position: 'fixed',
    backdrop: true,
    sx: { maxheight: '100px', maxWidth: '100px' }
};
export const LOADER_MAP = {
    singleDot: SingleDotLoader,
    threeDot: ThreeDotLoader
};
