import React from 'react'
import {
  DsBox,
  DsChip,
  DsIconButton,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface IDescriptionProps {
  title: string
  details?: Array<string | JSX.Element>
  titleVariant?:
    | 'headingBoldExtraLarge'
    | 'headingBoldLarge'
    | 'headingBoldMedium'
    | 'headingBoldSmall'
    | 'headingBoldExtraSmall'
    | 'subheadingSemiboldLarge'
    | 'subheadingSemiboldDefault'
  noBodyMargin?: boolean
  mt?: string | object
  mb?: string | object
  prefixIcon?: string
  sufixIcon?: string
  iconDimension?: number
  titleMb?: string
  defaultBodyColor?: boolean
  isMobileOnly?: boolean
}

function Description({
  title,
  details,
  titleVariant = 'headingBoldExtraLarge',
  noBodyMargin = false,
  mt = 'var(--ds-spacing-zero)',
  mb = 'var(--ds-spacing-zero)',
  prefixIcon = '',
  sufixIcon = '',
  iconDimension = 28,
  titleMb,
  defaultBodyColor,
  isMobileOnly = false
}: IDescriptionProps) {
  return (
    <DsBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: mt,
        mb: mb
      }}
    >
      <DsBox
        sx={{
          mb: titleMb || 'var(--ds-spacing-mild)',
          pr: 'calc(var(--ds-spacing-tepid) + var(--ds-spacing-mild) )',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {!!prefixIcon && (
          <DsRemixIcon
            className={prefixIcon}
            style={{
              marginRight: 'var(--ds-spacing-glacial)',
              width: `${iconDimension}px`,
              height: `${iconDimension}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
        )}
        <DsTypography
          sx={{
            display: 'inline-flex',
            alignItems: 'center'
          }}
          variant={titleVariant}
          color='var(--ds-colour-typoPrimary)'
        >
          {title}
          {isMobileOnly && (
            <DsChip
              sx={{
                marginLeft: 'var(--ds-spacing-frostbite)'
              }}
              color='default'
              label={
                <DsTypography variant='supportRegularInfo'>
                  Mobile Only
                </DsTypography>
              }
              type='nudge'
            />
          )}
        </DsTypography>
        {!!sufixIcon && (
          <DsIconButton
            sx={{
              marginRight: 'var(--ds-spacing-glacial)',
              width: `${iconDimension}px`,
              height: `${iconDimension}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon className={sufixIcon} />
          </DsIconButton>
        )}
      </DsBox>
      {details?.map((text: string | JSX.Element, index: number) => {
        return (
          <DsTypography
            key={`description-${index}`}
            variant='bodyRegularMedium'
            sx={{
              color: defaultBodyColor
                ? 'inhert'
                : 'var(--ds-colour-typoSecondary)',
              mb:
                noBodyMargin || index == details.length - 1
                  ? 'var(--ds-spacing-zero)'
                  : 'var(--ds-spacing-mild)'
            }}
          >
            {text}
          </DsTypography>
        )
      })}
    </DsBox>
  )
}

export default Description
