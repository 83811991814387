import React from 'react'
import {
  DsCheckbox,
  DsFormControl,
  DsFormControlLabel,
  DsFormLabel,
  DsTypography
} from '@am92/react-design-system'

import { IPlayGroundProps } from '~/src/Components/ComponentPlayground'

import { createThemeOption, icons } from '../utils'

const createCheckboxItem = (text: string, checked = false) => ({
  control: <DsCheckbox checked={checked} size='medium' />,
  label: (
    <DsTypography
      variant='bodyRegularMedium'
      color='var(--ds-colour-typoPrimary)'
    >
      {text}
    </DsTypography>
  )
})

const createMultiSelectionList = (count: number) => (
  <>
    <DsFormLabel>
      <DsTypography
        variant='bodyRegularMedium'
        color='var(--ds-colour-typoPrimary)'
      >
        Checkbox list heading
      </DsTypography>
    </DsFormLabel>
    {Array.from({ length: count }, (_, i) => (
      <DsFormControlLabel
        key={`item-${i + 1}`}
        control={<DsCheckbox defaultChecked={i === 0} size='medium' />}
        label={
          <DsTypography
            variant='bodyRegularMedium'
            color='var(--ds-colour-typoPrimary)'
          >
            {`Checkbox Item ${i + 1}`}
          </DsTypography>
        }
      />
    ))}
  </>
)

export const checkboxPlaygroundProps: IPlayGroundProps = {
  component: DsCheckbox,
  componentHeading: 'Checkbox',
  componentProperties: [
    {
      name: 'Selected',
      type: 'selected',
      list: [
        { label: 'Yes', value: { checked: true, size: 'medium' } },
        { label: 'No', value: { checked: false, size: 'medium' } }
      ]
    },
    {
      name: 'States',
      type: 'state',
      list: [
        { label: 'Default', value: {} },
        { label: 'Disabled', value: { disabled: true } }
      ]
    },
    {
      name: 'Theme',
      type: 'theme',
      list: [
        createThemeOption(icons.sun, 'Light Theme', 'light'),
        createThemeOption(icons.moon, 'Dark Theme', 'dark'),
        createThemeOption(icons.eye, 'High Contrast', 'highContrast')
      ]
    }
  ]
}

export const checkboxButtonPlaygroundProps: IPlayGroundProps = {
  component: DsFormControlLabel,
  componentHeading: 'Checkbox Button',
  componentProperties: [
    {
      name: 'Selected',
      type: 'selected',
      list: [
        { label: 'Yes', value: createCheckboxItem('Checkbox Item', true) },
        { label: 'No', value: createCheckboxItem('Checkbox Item') }
      ]
    },
    {
      name: 'States',
      type: 'state',
      list: [
        { label: 'Default', value: {} },
        {
          label: 'Disabled',
          value: {
            disabled: true
          }
        }
      ]
    },
    {
      name: 'Theme',
      type: 'theme',
      list: [
        createThemeOption(icons.sun, 'Light Theme', 'light'),
        createThemeOption(icons.moon, 'Dark Theme', 'dark'),
        createThemeOption(icons.eye, 'High Contrast', 'highContrast')
      ]
    }
  ]
}

export const multiSelectionPlaygroundProps: IPlayGroundProps = {
  component: DsFormControl,
  componentHeading: 'Multi-selection Checkboxes',
  componentProperties: [
    {
      name: 'List Items',
      type: 'listItems',
      list: [
        {
          label: '2',
          children: createMultiSelectionList(2),
          value: {
            child: 2
          }
        },
        {
          label: '3',
          children: createMultiSelectionList(3),
          value: {
            child: 3
          }
        },
        {
          label: '4',
          children: createMultiSelectionList(4),
          value: {
            child: 4
          }
        }
      ]
    },
    {
      name: 'Theme',
      type: 'theme',
      list: [
        createThemeOption(icons.sun, 'Light Theme', 'light'),
        createThemeOption(icons.moon, 'Dark Theme', 'dark'),
        createThemeOption(icons.eye, 'High Contrast', 'highContrast')
      ]
    }
  ]
}
