import { useLayoutEffect } from 'react'

import withRouter, { IWithRouterProps } from '../Hocs/withRouter'

const ScrollToTop = ({ location }: IWithRouterProps) => {
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    const container = document.getElementById('main-content')
    container?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.pathname])

  return null // No UI rendered, just side effects
}

export default withRouter(ScrollToTop)
