import React from 'react'
import {
  DsBox,
  DsFab,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface IStoryBookFigmaButtonProps {
  figmaLink: string
  storyBookLink?: string
}

const StoryBookFigmaButton = ({
  figmaLink,
  storyBookLink
}: IStoryBookFigmaButtonProps) => {
  return (
    <DsBox
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 'var(--ds-spacing-glacial)',
        mt: '-22px'
      }}
    >
      {storyBookLink && (
        <DsFab
          onClick={() =>
            window.open(storyBookLink, '_blank', 'noopener,noreferrer')
          }
          sx={{ width: 'inherit', height: '44px', fontWeight: 'normal' }}
        >
          <DsBox
            sx={{
              display: 'flex',
              gap: 'var(--ds-spacing-glacial)',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon
              sx={{ fontSize: '20px' }}
              className='ri-booklet-line'
            />
            <DsTypography
              sx={{ color: 'var(--ds-colour-typoOnSurface)' }}
              variant='bodyBoldMedium'
            >
              Open in Storybook
            </DsTypography>
          </DsBox>
        </DsFab>
      )}

      <DsFab
        onClick={() => window.open(figmaLink, '_blank', 'noopener,noreferrer')}
        sx={{ width: 'inherit', height: '44px', fontWeight: 'normal' }}
      >
        <DsBox
          sx={{
            display: 'flex',
            gap: 'var(--ds-spacing-glacial)',
            alignItems: 'center'
          }}
        >
          <DsRemixIcon sx={{ fontSize: '20px' }} className='ri-figma-line' />
          <DsTypography
            sx={{ color: 'var(--ds-colour-typoOnSurface)' }}
            variant='bodyBoldMedium'
          >
            Open in Figma
          </DsTypography>
        </DsBox>
      </DsFab>
    </DsBox>
  )
}

export default StoryBookFigmaButton
