import React from 'react'
import { getTheme, useMediaQuery } from '@am92/react-design-system'

export interface IWithDeviceDetails {
  breakpoint: { [key: string]: boolean }[]
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  tabletLSMin: boolean
  potraitOrientation: boolean
}

export default function withDeviceDetails(
  WrappedComponent: React.ComponentType<any>
) {
  const Comp = (props: any) => {
    const theme = getTheme()
    const potraitOrientation =
      window?.screen?.orientation?.type?.includes('portrait') || false

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'))
    const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const TabletLandscapeMin = useMediaQuery(theme.breakpoints.up(1010))

    return (
      <WrappedComponent
        {...props}
        breakpoint={theme.breakpoints}
        isMobile={isMobile}
        isTablet={isTablet}
        isDesktop={isDesktop}
        tabletLSMin={TabletLandscapeMin}
        potraitOrientation={potraitOrientation}
      />
    )
  }

  return Comp
}
