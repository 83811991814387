import { APP_TITLE } from '~/src/Configurations/env'

export const THEME_MODE_STORAGE_KEY: string = `${APP_TITLE.replaceAll(
  ' ',
  '-'
)}-mode`

export const DEFAULT_GET_STARTED_WIDTH = '66.67%'

export const DEFAULT_FOUNDATION_WIDTH = '63.64%'

export const COMPONENT_DEFAULT_WIDTH = '91.68%'

export const BOOKMARK_MENU_WIDTH = '26.82%'
