export const DsBackdropOverrides = {
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'var(--ds-colour-overlay)'
            },
            invisible: {
                backgroundColor: 'transparent'
            }
        }
    }
};
