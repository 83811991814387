export const FIGMA_IMAGE = {
  image: new URL('~/src/Assets/images/symbol.svg', import.meta.url).href,
  title: 'figma Image'
}

export const BANNER_IMAGE = [
  {
    src: new URL(
      '~/src/Assets/images/desktopBanner.svg?as=webp',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'image/webp'
  },
  {
    src: new URL('~/src/Assets/images/desktopBanner.svg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'image/png'
  }
]

export const MOBILE_BANNER_IMAGE = [
  {
    src: new URL(
      '~/src/Assets/images/mobileBanner.svg?as=webp',
      import.meta.url
    ).href,
    alt: 'Banner Image',
    as: 'image/webp'
  },
  {
    src: new URL('~/src/Assets/images/mobileBanner.svg', import.meta.url).href,
    alt: 'Banner Image',
    as: 'image/png'
  }
]

export const ABOUT_SUBZERO_IMAGES = [
  {
    image: [
      {
        src: new URL('~/src/Assets/images/getStarted1.svg', import.meta.url)
          .href,
        alt: 'Intentional Image',
        as: 'image/webp'
      },
      {
        src: new URL('~/src/Assets/images/getStarted1.svg', import.meta.url)
          .href,
        alt: 'Intentional Image',
        as: 'image/png'
      }
    ],
    title: 'Intentional'
  },
  {
    image: [
      {
        src: new URL('~/src/Assets/images/getStarted2.svg', import.meta.url)
          .href,
        alt: 'Accessible Image',
        as: 'image/webp'
      },
      {
        src: new URL('~/src/Assets/images/getStarted2.svg', import.meta.url)
          .href,
        alt: 'Accessible Image',
        as: 'image/png'
      }
    ],
    title: 'Accessible'
  },
  {
    image: [
      {
        src: new URL('~/src/Assets/images/getStarted3.svg', import.meta.url)
          .href,
        alt: 'Intuitive Image',
        as: 'image/webp'
      },
      {
        src: new URL('~/src/Assets/images/getStarted3.svg', import.meta.url)
          .href,
        alt: 'Intuitive Image',
        as: 'image/png'
      }
    ],
    title: 'Intuitive'
  }
]

export const PLACEHOLDER = [
  {
    src: new URL('~/src/Assets/images/colourPlaceholder.svg', import.meta.url)
      .href,
    alt: 'Placeholder Image',
    as: 'image/png'
  }
]

export const FEEDBACK = [
  {
    src: new URL('~/src/Assets/images/successTick.gif', import.meta.url).href,
    alt: 'Success Tick',
    as: 'image/svg'
  }
]
