import React, { ReactNode } from 'react'
import { DsBox, DsRemixIcon } from '@am92/react-design-system'

interface IThemeOption {
  label: ReactNode
  value: { mode: string }
}

export const icons = {
  sun: 'ri-sun-fill',
  moon: 'ri-moon-fill',
  eye: 'ri-eye-fill',
  add: 'ri-add-fill',
  star: 'ri-star-fill',
  heart: 'ri-heart-3-fill'
}

export const createThemeOption = (
  icon: string,
  label: string,
  mode: string
): IThemeOption => ({
  label: (
    <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
      <DsRemixIcon
        className={icon}
        sx={{ fontSize: '16px', mr: 'var(--ds-spacing-quickFreeze)' }}
      />
      {label}
    </DsBox>
  ),
  value: { mode }
})
