import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Accordion from '@mui/material/Accordion';
import { useThemeProps } from '@mui/system';
import { DsAccordionDetails } from '../DsAccordionDetails';
import { DsAccordionSummary } from '../DsAccordionSummary';
import { DsRemixIcon } from '../DsRemixIcon';
export const DsAccordion = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiAccordion'
    });
    const { header, HeaderProps, summary, SummaryProps, expandIcon = (_jsx(DsRemixIcon, { className: "ri-arrow-drop-down-line", fontSize: "bitterCold" })), ...AccordionProps } = props;
    return (_jsxs(Accordion, { ...AccordionProps, children: [_jsx(DsAccordionSummary, { expandIcon: expandIcon, ...HeaderProps, children: header }), summary && (_jsx(DsAccordionDetails, { ...SummaryProps, children: summary }))] }));
};
