export const APP_TITLE = process.env.APP_TITLE || ''
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION || ''
export const APP_PRECONNECT_DOMAINS = process.env.APP_PRECONNECT_DOMAINS || ''
export const AS_API_DOMAIN = process.env.AS_API_DOMAIN || ''
export const AS_API_KEY = process.env.AS_API_KEY || ''
export const AS_ENABLE_CRPTOGRAPHY =
  process.env.AS_ENABLE_CRPTOGRAPHY === 'true'
export const AS_API_TIMEOUT = parseInt(process.env.AS_API_TIMEOUT || '', 10)
export const APP_EMAIL_TEMPLATE_ID = process.env.APP_EMAIL_TEMPLATE_ID || ''
export const APP_EMAIL_SERVICE_ID = process.env.APP_EMAIL_SERVICE_ID || ''
export const APP_EMAIL_USER_ID = process.env.APP_EMAIL_USER_ID || ''
export const APP_MOBILE_ANIMATION = process.env.APP_MOBILE_ANIMATION
export const APP_WEB_ANIMATION = process.env.APP_WEB_ANIMATION
export const APP_IPAD_ANIMATION = process.env.APP_IPAD_ANIMATION
