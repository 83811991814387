import React from 'react'
import { DsBox, DsGrid, DsTypography } from '@am92/react-design-system'

interface IPlaygroundHeadingDescriptionProps {
  dyanmicSubheading: string
  dynamicDescription: string
}

const PlaygroundHeadingDescription = ({
  dyanmicSubheading,
  dynamicDescription
}: IPlaygroundHeadingDescriptionProps) => {
  return (
    <DsGrid
      item
      xs={12}
      sx={{ backgroundColor: 'var(--ds-colour-surfaceBackground)' }}
    >
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'var(--ds-spacing-mild)',
          gap: 'var(--ds-spacing-glacial)'
        }}
      >
        <DsTypography variant='headingBoldMedium' component='div'>
          {dyanmicSubheading}
        </DsTypography>
        <DsTypography
          variant='bodyRegularMedium'
          color='var(--ds-colour-typoSecondary)'
        >
          {dynamicDescription}
        </DsTypography>
      </DsBox>
    </DsGrid>
  )
}

export default PlaygroundHeadingDescription
