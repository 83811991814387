import React from 'react'

import DynamicComponent from '~/src/Components/DynamicComponent'

import { CheckboxComponentPage } from './PageDetails'

const CheckboxPage = () => {
  return <DynamicComponent pageDetails={CheckboxComponentPage} />
}

export default CheckboxPage
