import React, { useState } from 'react'
import {
  DsBox,
  DsDivider,
  DsGrid,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withDeviceDetails, {
  IWithDeviceDetails
} from '../Hocs/withDeviceDetails'

import PlaygroundHeadingDescription from './PlaygroundHeadingDescription'
import { renderPropertyControlMap } from './PropertyControlMap'

export interface IComponentProperty {
  name: string
  type: string
  list: { value: any; [key: string]: any }[]
  defaultValue?: object
  showCustomizationDividerAfter?: boolean
  [key: string]: any
}

export interface IPlayGroundProps {
  componentHeading?: string
  componentSubHeading?: string
  component: React.ElementType | null
  componentDescription?: string
  shouldUseSelectForSize?: boolean
  showTextwithIcon?: boolean
  componentProperties: IComponentProperty[]
  dynamicCustomisation?: { [key: string]: IComponentProperty[] }
  handleChange?: (argsOne: any, argsTwo: any, argsThree: any) => any
  dynamicKey?: string
  playgroundHeight?: string
}

interface IPlaygroundProps extends IWithDeviceDetails {
  playgroundProps: IPlayGroundProps
  sxProps?: object
}

const getDynamicComponents = (selectedPropValues: { [key: string]: any }) => {
  let dynamicComponent, customisationKey
  for (const value of Object.values(selectedPropValues)) {
    if (value?.component) {
      dynamicComponent = value.component
      customisationKey = value.customisationKey
    }
  }
  return {
    dynamicComponent: dynamicComponent,
    customisationKey: customisationKey
  }
}

const createInitialSelectedState = (data: IPlayGroundProps) => {
  const selectedProps: {
    [key: string]: any
  } = {}
  data.componentProperties?.map(property => {
    selectedProps[property.type] =
      property.defaultValue || property.list[0].value
  })

  const customisationKey = getDynamicComponents(selectedProps).customisationKey
  if (customisationKey && data.dynamicCustomisation) {
    data.dynamicCustomisation[customisationKey]?.map(property => {
      selectedProps[property.type] =
        property.defaultValue || property.list[0].value
    })
  }

  return selectedProps
}

const mapSelectedPropsToComponentProps = (
  componentProperties: IComponentProperty[] = [],
  selectedProps: { [key: string]: any }
) => {
  return componentProperties.reduce((acc: any, componentProperty) => {
    const { type, list } = componentProperty

    // Find the selected value from selectedProps corresponding to the componentProperty
    let selectedValue = selectedProps[type]
    let propDef
    if (selectedValue && typeof selectedValue === 'object') {
      propDef = selectedValue
    } else if (selectedValue && typeof selectedValue === 'boolean') {
      //This is for toggles by default value,need to pass the icon as a prop
      const { value, ...rest } = list[0]
      propDef = rest
      selectedValue = rest
    }

    if (propDef?.sx && acc?.sx) {
      propDef.sx = { ...propDef.sx, ...acc.sx }
    }
    return propDef ? { ...acc, ...selectedValue } : acc
  }, {})
}

const ComponentPlayground = ({
  playgroundProps,
  sxProps = {},
  isMobile
}: IPlaygroundProps) => {
  const [playgroundMode, setPlaygroundMode] = useState('light')
  const [dyanmciComponentProps, setDynamicComponentProps] = useState<{
    [key: string]: any
  }>({})

  const [selectedPropValues, setSelectedPropValues] = useState<{
    [key: string]: any
  }>(createInitialSelectedState(playgroundProps))

  const handleInitialdDynamicValue = (
    value: any,
    updatedValues: {
      [x: string]: any
    }
  ) => {
    if (value.customisationKey && playgroundProps.dynamicCustomisation) {
      const dynamicSelectedProps: { [key: string]: any } = {}

      const dynamicCustomisation =
        playgroundProps.dynamicCustomisation[value.customisationKey]

      dynamicCustomisation?.map((property: IComponentProperty) => {
        dynamicSelectedProps[property.type] =
          property.defaultValue || property.list[0].value
      })

      updatedValues = { ...updatedValues, ...dynamicSelectedProps }
    }
    return updatedValues
  }

  const handleThemeChange = (event: any) => {
    const { value } = event.target
    setPlaygroundMode(value.mode)
  }

  const handleSelectionChange = (event: any, propType: string) => {
    const { value } = event.target
    setSelectedPropValues(prev => {
      let updatedValues = { ...prev }
      updatedValues[propType] = value

      updatedValues = handleInitialdDynamicValue(value, updatedValues)
      return updatedValues
    })
    if (propType === 'theme') {
      handleThemeChange(event)
    }
  }

  const handleCounterChange = (value: any, propType: string) => {
    setSelectedPropValues(prev => {
      const updatedValues = { ...prev }
      updatedValues[propType] = value
      return updatedValues
    })
  }

  const handleToggleChange = (
    propType: string,
    value: boolean,
    componentProperties: IComponentProperty[]
  ) => {
    const { value: iconValue, ...rest } = componentProperties[0]
    setSelectedPropValues(prev => {
      const iconToSet = rest
      const updatedValues = {
        ...prev,
        [propType]: value ? iconToSet : null
      }
      return updatedValues
    })
  }

  const {
    component,
    componentHeading = '',
    componentSubHeading,
    componentDescription,
    dynamicCustomisation,
    shouldUseSelectForSize = true,
    showTextwithIcon = true,
    handleChange,
    playgroundHeight,
    dynamicKey = 'value'
  } = playgroundProps

  let { componentProperties } = playgroundProps

  const Component =
    component || getDynamicComponents(selectedPropValues).dynamicComponent

  if (!component) {
    const customisationKey =
      getDynamicComponents(selectedPropValues).customisationKey
    const selectedCustomisation = dynamicCustomisation
      ? dynamicCustomisation[customisationKey]
      : []
    componentProperties = [...componentProperties, ...selectedCustomisation]
  }

  const componentProps = mapSelectedPropsToComponentProps(
    componentProperties,
    selectedPropValues
  )

  let dynamicDescription = componentDescription
  let dyanmicSubheading = componentSubHeading

  const getDynamicComponentChild = (): string => {
    for (const property of componentProperties) {
      const selectedValue = selectedPropValues[property.type]
      if (!selectedValue) continue
      const matchedItem = property.list?.find(
        item => JSON.stringify(item.value) === JSON.stringify(selectedValue)
      )

      if (matchedItem) {
        dynamicDescription =
          matchedItem?.componentDescription || dynamicDescription
        dyanmicSubheading =
          matchedItem?.componentSubHeading || dyanmicSubheading
        if (matchedItem.children) return matchedItem.children
      }
    }
    return showTextwithIcon ? componentHeading : ''
  }

  const handleComponentChange = (
    argsOne: any,
    argsTwo: any,
    argsThree: any
  ) => {
    if (handleChange) {
      const updatedValue = handleChange(argsOne, argsTwo, argsThree)
      setDynamicComponentProps({ [dynamicKey]: updatedValue })
    }
  }

  return (
    <>
      {
        <DsBox key={componentHeading} sx={sxProps}>
          {componentHeading && (
            <DsBox sx={{ mb: 'var(--ds-spacing-warm)' }}>
              <DsTypography variant='headingBoldMedium'>
                {componentHeading}
              </DsTypography>
            </DsBox>
          )}

          <DsGrid
            container
            sx={{
              width: '100%',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              borderRadius: 'var(--ds-radius-bitterCold)',
              border: '1px solid var(--ds-colour-strokeDefault)',
              overflow: 'hidden'
            }}
          >
            <DsGrid
              item
              xs={isMobile ? 12 : 7.5}
              sx={{
                backgroundColor: 'var(--ds-colour-neutral1)',
                minHeight: playgroundHeight || '524px'
              }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              // Added this to provide theme data-attrbute that is used by MUI to pick theme
              data-mui-color-scheme={playgroundMode}
            >
              {getDynamicComponentChild() ? (
                <Component
                  {...componentProps}
                  {...dyanmciComponentProps}
                  onChange={(argsOne: any, argsTwo: any, argsThree: any) =>
                    handleComponentChange(argsOne, argsTwo, argsThree)
                  }
                >
                  {getDynamicComponentChild()}
                </Component>
              ) : (
                <Component
                  {...componentProps}
                  {...dyanmciComponentProps}
                  onChange={(argsOne: any, argsTwo: any, argsThree: any) =>
                    handleComponentChange(argsOne, argsTwo, argsThree)
                  }
                />
              )}
            </DsGrid>

            <DsGrid item xs={isMobile ? 12 : 4.5}>
              <DsStack
                sx={{
                  p: 'var(--ds-spacing-mild)',
                  pt: 'calc(var(--ds-spacing-warm) + var(--ds-spacing-deepFreeze))'
                }}
              >
                {componentProperties?.map(property => {
                  return (
                    <React.Fragment key={property.type}>
                      {renderPropertyControlMap(
                        property,
                        componentProperties,
                        selectedPropValues,
                        shouldUseSelectForSize,
                        handleSelectionChange,
                        handleCounterChange,
                        handleToggleChange
                      )}
                      {property.showCustomizationDividerAfter && (
                        <>
                          <DsDivider sx={{ my: 'var(--ds-spacing-mild)' }} />
                          <DsBox sx={{ mb: 'var(--ds-spacing-bitterCold)' }}>
                            <DsTypography variant='subheadingSemiboldDefault'>
                              Customization
                            </DsTypography>
                          </DsBox>
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </DsStack>
            </DsGrid>
            {dynamicDescription && dyanmicSubheading && (
              <PlaygroundHeadingDescription
                dyanmicSubheading={dyanmicSubheading}
                dynamicDescription={dynamicDescription}
              />
            )}
          </DsGrid>
        </DsBox>
      }
    </>
  )
}

export default withDeviceDetails(ComponentPlayground)
