import React from 'react'
import {
  DsBox,
  DsSelect,
  DsToggle,
  DsTypography
} from '@am92/react-design-system'

import { IComponentProperty } from '~/src/Components/ComponentPlayground'
import CounterCard from '~/src/Components/CounterCard'

import DigitSelectionCard from './DigitSelectionCard'

const togglePropTypes = [
  't_trailingIcon',
  't_leadingIcon',
  't_titleIcon',
  't_kickerIcon',
  't_closeButtonIcon',
  't_removableIcon',
  't_tooltipIcon',
  't_helperText',
  't_cta',
  't_characterCount',
  't_scrollBar',
  't_showHide',
  't_helperButton',
  't_voiceSearch',
  't_sliderTitle',
  't_inputField',
  't_supportText',
  't_tooltip',
  't_scrollbar',
  't_title'
]

const selectPropTypes = ['size', 'default']

const digitPropsTypes = ['digits']

const renderToggleControl = (
  propType: string,
  componentProperties: any,
  selectedPropValues: any,
  handleToggleChange: any,
  propIndex: number
) => {
  const toggleValue = Boolean(selectedPropValues[propType])
  return (
    <React.Fragment key={propType}>
      <DsBox
        sx={{
          display: 'flex',
          gap: 'var(--ds-spacing-glacial)',
          mb:
            componentProperties[propIndex].showCustomizationDividerAfter ||
            propIndex === componentProperties.length - 1
              ? 'var(--ds-spacing-zero)'
              : 'var(--ds-spacing-bitterCold)'
        }}
      >
        <DsToggle
          name={propType}
          value={toggleValue}
          onChange={(name, value) =>
            handleToggleChange(name, value, componentProperties[propIndex].list)
          }
        />
        <DsTypography variant='bodyRegularMedium'>
          {componentProperties[propIndex]?.name}
        </DsTypography>
      </DsBox>
    </React.Fragment>
  )
}

const renderSelectControl = (
  propType: string,
  componentProperties: any,
  selectedPropValues: any,
  handleSelectionChange: any,
  propIndex: number
) => {
  return (
    <DsSelect
      key={propType}
      name={propType}
      label={componentProperties[propIndex].name}
      options={componentProperties[propIndex].list}
      value={selectedPropValues[propType] as string}
      onChange={event => handleSelectionChange(event, propType)}
      sx={{
        '& .MuiInputBase-input:focus': {
          backgroundColor: 'inherit'
        },
        mb:
          componentProperties[propIndex].showCustomizationDividerAfter ||
          propIndex === componentProperties.length - 1
            ? 'var(--ds-spacing-zero)'
            : 'var(--ds-spacing-bitterCold)'
      }}
    />
  )
}

const renderCounterControl = (
  propType: string,
  componentProperties: any,
  selectedPropValues: any,
  handleCounterChange: any,
  propIndex: number
) => (
  <CounterCard
    key={propType}
    label={componentProperties[propIndex].name}
    value={selectedPropValues[propType]}
    list={componentProperties[propIndex].list}
    handleChange={value => handleCounterChange(value, propType)}
    sxProps={{
      mb:
        componentProperties[propIndex].showCustomizationDividerAfter ||
        propIndex === componentProperties.length - 1
          ? 'var(--ds-spacing-zero)'
          : 'var(--ds-spacing-bitterCold)'
    }}
  />
)

const renderDigitControl = (
  propType: string,
  componentProperties: any,
  selectedPropValues: any,
  handleCounterChange: any,
  propIndex: number
) => (
  <DigitSelectionCard
    key={propType}
    label={componentProperties[propIndex].name}
    value={selectedPropValues[propType]}
    list={componentProperties[propIndex].list}
    handleChange={value => handleCounterChange(value, propType)}
    sxProps={{
      mb:
        componentProperties[propIndex].showCustomizationDividerAfter ||
        propIndex === componentProperties.length - 1
          ? 'var(--ds-spacing-zero)'
          : 'var(--ds-spacing-bitterCold)'
    }}
  />
)

export const renderPropertyControlMap: any = (
  propType: IComponentProperty,
  componentProperties: IComponentProperty[],
  selectedPropValues: any,
  shouldUseSelectForSize: boolean,
  handleSelectionChange: any,
  handleCounterChange: any,
  handleToggleChange: any
) => {
  const propertyType: string = propType.type
  const propIndex = componentProperties.findIndex(
    (property: IComponentProperty) => property.type === propertyType
  )
  // to render toggle option
  if (togglePropTypes.includes(propertyType)) {
    return renderToggleControl(
      propertyType,
      componentProperties,
      selectedPropValues,
      handleToggleChange,
      propIndex
    )
  }

  // size - dropdown & counter
  if (selectPropTypes.includes(propertyType)) {
    return shouldUseSelectForSize
      ? renderSelectControl(
          propertyType,
          componentProperties,
          selectedPropValues,
          handleSelectionChange,
          propIndex
        )
      : renderCounterControl(
          propertyType,
          componentProperties,
          selectedPropValues,
          handleCounterChange,
          propIndex
        )
  }

  if (digitPropsTypes.includes(propertyType)) {
    return renderDigitControl(
      propertyType,
      componentProperties,
      selectedPropValues,
      handleCounterChange,
      propIndex
    )
  }

  // dropdown menu
  return renderSelectControl(
    propertyType,
    componentProperties,
    selectedPropValues,
    handleSelectionChange,
    propIndex
  )
}
