import React from 'react'
import {
  DsStack,
  DsTag,
  DsTagGroup,
  DsTypography
} from '@am92/react-design-system'

interface ICounterCardProps {
  label: string
  list: {
    label: string
    value: any
  }[]
  value: { [key: string]: any }
  handleChange: (value: any) => void
  sxProps?: object
}

const DigitSelectionCard = ({
  label,
  list,
  value,
  handleChange,
  sxProps
}: ICounterCardProps) => {
  const handleCounterChange = (e: any, value: any) => {
    handleChange(value)
  }

  return (
    <DsStack
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <DsTypography
        variant='bodyRegularMedium'
        sx={{ mr: 'var(--ds-spacing-quickFreeze)' }}
      >
        {label}
      </DsTypography>
      <DsTagGroup onChange={handleCounterChange} value={value} sx={sxProps}>
        {list.map((item, index: number) => (
          <DsTag label={item.label} value={item.value} key={index} />
        ))}
      </DsTagGroup>
    </DsStack>
  )
}

export default DigitSelectionCard
