import { NavigateOptions, To } from 'react-router-dom'

type TAppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

type TAppRoute = {
  [key: string]: TAppRouteItem
}
export const BASE_PATHS = {
  GET_STARTED: 'get-started',
  FOUNDATIONS: 'foundations',
  COMPONENTS: 'components'
}

const APP_ROUTES: TAppRoute = {
  HOME: {
    pathname: '/'
  },

  ABOUT_SUBZERO: {
    pathname: `/${BASE_PATHS.GET_STARTED}/about-subzero`
  },

  DESIGNERS_TOOLKIT: {
    pathname: `/${BASE_PATHS.GET_STARTED}/designers-toolkit`
  },

  DEVELOPERS_TOOLKIT: {
    pathname: `/${BASE_PATHS.GET_STARTED}/developers-toolkit`
  },

  FEEDBACK: {
    pathname: `/${BASE_PATHS.GET_STARTED}/feedback`
  },

  TYPOGRAPHY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/typography`
  },

  COLOURS: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/colours`
  },

  ELEVATION: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/elevation`
  },

  ICONOGRAPHY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/iconography`
  },

  LAYOUT_SPACING: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/layout-and-spacing`
  },

  DATA_VISUALISATION: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/data-visualisation`
  },

  DESIGN_TOKENS: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/design-tokens`
  },

  ACCESSIBILITY: {
    pathname: `/${BASE_PATHS.FOUNDATIONS}/accessibility`
  },

  ACCORDION: {
    pathname: `/${BASE_PATHS.COMPONENTS}/accordion`
  },
  BUTTON: {
    pathname: `/${BASE_PATHS.COMPONENTS}/button`
  },

  APP_BAR: {
    pathname: `/${BASE_PATHS.COMPONENTS}/app-bar`
  },

  BADGE: {
    pathname: `/${BASE_PATHS.COMPONENTS}/badge`
  },

  BOTTOM_NAVIGATION: {
    pathname: `/${BASE_PATHS.COMPONENTS}/bottom-navigation`
  },

  BREADCRUMB: {
    pathname: `/${BASE_PATHS.COMPONENTS}/breadcrumb`
  },

  AVATAR: {
    pathname: `/${BASE_PATHS.COMPONENTS}/avatar`
  },

  CHIP: {
    pathname: `/${BASE_PATHS.COMPONENTS}/chip`
  },

  DIALOG: {
    pathname: `/${BASE_PATHS.COMPONENTS}/dialog`
  },

  TOGGLE: {
    pathname: `/${BASE_PATHS.COMPONENTS}/toggle`
  },

  BOTTOM_SHEET: {
    pathname: `/${BASE_PATHS.COMPONENTS}/bottom-sheet`
  },

  INPUT_FIELD: {
    pathname: `/${BASE_PATHS.COMPONENTS}/input-field`
  },

  TAGS: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tag`
  },

  CAROUSEL: {
    pathname: `/${BASE_PATHS.COMPONENTS}/carousel`
  },

  FAB: {
    pathname: `/${BASE_PATHS.COMPONENTS}/fab`
  },

  DIVIDER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/divider`
  },

  COACHMARK: {
    pathname: `/${BASE_PATHS.COMPONENTS}/coachmark`
  },

  ONBOARDING: {
    pathname: `/${BASE_PATHS.COMPONENTS}/onboarding`
  },

  MENU: {
    pathname: `/${BASE_PATHS.COMPONENTS}/menu`
  },

  RADIO_BUTTON: {
    pathname: `/${BASE_PATHS.COMPONENTS}/radio-button`
  },

  ADD_ITEM: {
    pathname: `/${BASE_PATHS.COMPONENTS}/add-item`
  },

  CHECKBOX: {
    pathname: `/${BASE_PATHS.COMPONENTS}/checkbox`
  },

  SWITCH: {
    pathname: `/${BASE_PATHS.COMPONENTS}/switch`
  },

  SEARCH: {
    pathname: `/${BASE_PATHS.COMPONENTS}/search`
  },

  SLIDER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/slider`
  },

  TABS: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tabs`
  },

  TOAST: {
    pathname: `/${BASE_PATHS.COMPONENTS}/toast`
  },

  TOOLTIP: {
    pathname: `/${BASE_PATHS.COMPONENTS}/tooltip`
  },

  HEADER: {
    pathname: `/${BASE_PATHS.COMPONENTS}/header`
  },

  LINK: {
    pathname: `/${BASE_PATHS.COMPONENTS}/link`
  },

  V2: {
    pathname: '/v2/*'
  },

  ANY: {
    pathname: '*'
  }
}

export default APP_ROUTES
