import { jsx as _jsx } from "react/jsx-runtime";
import { DS_LOADER_DEFAULT_PROPS, LOADER_MAP } from './DsLoader.Types';
import { DsBackdrop } from '../DsBackdrop';
import { DsBox } from '../DsBox';
export const DsLoader = inProps => {
    const props = { ...DS_LOADER_DEFAULT_PROPS, ...inProps };
    const { 'ds-variant': loaderVariant = 'threeDot', color, position, backdrop, BackdropProps, ...boxProps } = props;
    const LoaderElement = LOADER_MAP[loaderVariant];
    const { sx: wrapperStyleProps, ...restBoxProps } = boxProps;
    return (_jsx(DsBackdrop, { ...BackdropProps, open: true, invisible: !backdrop, children: _jsx(DsBox, { sx: [
                {
                    position,
                    color: `var(--ds-colour-${color}, var(--ds-colour-dotLoader))`
                },
                backdrop
                    ? {
                        color: `var(--ds-colour-${color}, var(--palette-common-white))`
                    }
                    : {},
                {
                    color: color
                },
                wrapperStyleProps || {}
            ], ...restBoxProps, children: _jsx(LoaderElement, {}) }) }));
};
