import React, { useState } from 'react'
import {
  DsAccordion,
  DsBox,
  DsDivider,
  DsImage,
  DsRemixIcon,
  DsTypography
} from '@am92/react-design-system'

interface IAnatomyStructureProps {
  anatomyStructureProp: {
    src: string
    alt: string
    as: string
  }[]
}

export const AnatomyStructure = ({
  anatomyStructureProp
}: IAnatomyStructureProps) => {
  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = (
    _event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded)
  }

  return (
    <DsBox
      sx={{
        width: '100%',
        mt: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))',
        borderRadius: 'var(--ds-radius-bitterCold)',
        border: '1px solid var(--ds-colour-strokeDefault)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        mb: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-frostbite))'
      }}
    >
      <DsAccordion
        sx={{
          width: '100%',
          '& .MuiAccordionDetails-root': {
            padding: 0,
            borderBottom: 'none'
          },
          '& .MuiAccordionSummary-root': {
            padding: 0
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            backgroundColor: 'var(--ds-colour-surfaceBackground)'
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            top: '0',
            right: '0'
          },
          '& .MuiCollapse-wrapper': {
            backgroundColor: '#FFFFFF'
          }
        }}
        expanded={expanded}
        onChange={handleAccordionChange}
        expandIcon={
          <DsBox
            sx={{
              display: 'flex',
              width: '32px',
              height: '32px',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              borderRadius: 'var(--ds-spacing-cool)',
              padding: 'var(--ds-spacing-quickFreeze)',
              m: 'var(--ds-spacing-mild)'
            }}
          >
            <DsRemixIcon className='ri-arrow-down-s-line' />
          </DsBox>
        }
        header={
          <DsBox sx={{ width: '100%' }}>
            <DsBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 'var(--ds-spacing-mild)',
                gap: 'var(--ds-spacing-glacial)'
              }}
            >
              <DsRemixIcon
                className='ri-artboard-2-line'
                sx={{
                  fontSize: '28px'
                }}
              />
              <DsTypography
                variant='headingBoldMedium'
                color='var(--ds-colour-typoPrimary)'
              >
                Anatomy & Structure
              </DsTypography>
            </DsBox>
            <DsDivider
              sx={{
                opacity: 0.6,
                width: '95%',
                mx: 'auto'
              }}
            />
            {!expanded && (
              <DsBox sx={{ p: 'var(--ds-spacing-mild)', width: '100%' }}>
                <DsTypography
                  variant='bodyRegularMedium'
                  color='var(--ds-colour-typoSecondary)'
                >
                  View parts and structure of the component
                </DsTypography>
              </DsBox>
            )}
          </DsBox>
        }
        summary={
          <DsImage
            style={{
              marginTop: 'var(--ds-spacing-mild)'
            }}
            width={'100%'}
            srcSet={anatomyStructureProp}
          />
        }
      />
    </DsBox>
  )
}
