import React, { useState } from 'react'
import {
  DsBox,
  DsDrawer,
  DsRemixIcon,
  DsStack
} from '@am92/react-design-system'

import HeaderIcon from './HeaderIcon'
import Navbar from './Navbar'

interface IMobileNavbar {
  color: string
  hambergurColor?: string
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
}

const MobileNavbar = ({ color, position, hambergurColor }: IMobileNavbar) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleNavbar = () => {
    setIsOpen(prev => !prev)
  }

  return (
    <>
      <DsStack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          height: '64px',
          width: '100%',
          zIndex: 10,
          position: position || 'sticky',
          top: '0',
          px: 'var(--ds-spacing-bitterCold)',
          backgroundColor: color
        }}
      >
        <DsRemixIcon
          className='ri-menu-line'
          sx={{ fontSize: '24px', cursor: 'pointer', color: hambergurColor }}
          onClick={toggleNavbar}
        />
        <DsBox
          sx={{
            width: { xs: '119px', lg: '166px' },
            height: { xs: '24px', lg: '36px' }
          }}
        >
          <HeaderIcon
            color={hambergurColor ? '#282828' : 'var(--ds-colour-typoPrimary)'}
          />
        </DsBox>
      </DsStack>

      <DsDrawer
        anchor='left'
        open={isOpen}
        onClose={toggleNavbar}
        sx={{
          width: '0px',
          height: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Navbar open={isOpen} onClose={toggleNavbar} />
      </DsDrawer>
    </>
  )
}

export default MobileNavbar
