import React from 'react'
import { DsRemixIcon, DsStack, DsTypography } from '@am92/react-design-system'

interface ICounterCardProps {
  label?: string
  list: {
    label: string
    value: any
  }[]
  value: any
  handleChange: (value: any) => void
  sxProps?: object
  disabled?: boolean
  elevation?: string
}

const CounterCard = ({
  label,
  list,
  value,
  handleChange,
  sxProps,
  disabled,
  elevation
}: ICounterCardProps) => {
  // to select this initial, it must have reference equality with the option in order to be selected.
  const selectedOption = () => {
    const selectedValue = list.find(item => item.value === value)
    return selectedValue?.label || ''
  }

  const hasPrevNextOption = () => {
    const selectedIndex = list.findIndex(item => item.value == value)

    return {
      hasNext: selectedIndex !== list.length - 1,
      hasPrev: selectedIndex !== 0,
      index: selectedIndex
    }
  }

  const handleCounterChange = (type: string) => {
    const prevNextValues = hasPrevNextOption()
    if (type === 'prev' && prevNextValues.hasPrev && !disabled) {
      const prevValue = list[prevNextValues.index - 1]
      handleChange(prevValue.value)
    } else if (type === 'next' && prevNextValues.hasNext && !disabled) {
      const nextValue = list[prevNextValues.index + 1]
      handleChange(nextValue.value)
    }
  }

  return (
    <DsStack direction='row' alignItems='center' sx={sxProps}>
      {label && (
        <DsTypography
          variant='bodyRegularMedium'
          color='var(--ds-colour-typoPrimary)'
          sx={{
            textTransform: 'capitalize',
            mr: 'var(--ds-spacing-frostbite)'
          }}
        >
          {label}
        </DsTypography>
      )}
      <DsStack
        direction='row'
        alignItems='center'
        justifyContent={'space-between'}
        spacing={1}
        sx={{
          width: '90px',
          backgroundColor: disabled
            ? 'var(--ds-colour-stateDisabledSurface)'
            : 'var(--ds-colour-surfaceBackground)',
          borderRadius: 'var(--ds-spacing-cool)',
          p: 'var(--ds-spacing-glacial)',
          boxShadow: elevation
        }}
      >
        <DsStack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            '&.MuiStack-root': {
              m: 'var(--ds-spacing-zero) !important'
            },
            borderRadius: '50%',
            cursor:
              hasPrevNextOption().hasPrev && !disabled
                ? 'pointer'
                : 'not-allowed',
            width: 'var(--ds-spacing-cool)',
            height: 'var(--ds-spacing-cool)',
            '&:hover': {
              backgroundColor:
                hasPrevNextOption().hasPrev && !disabled
                  ? 'var(--ds-colour-stateSelectedPrimaryHover)'
                  : ''
            }
          }}
          onClick={() => handleCounterChange('prev')}
        >
          <DsRemixIcon
            className='ri-subtract-line'
            fontSize='small'
            color={
              hasPrevNextOption().hasPrev && !disabled
                ? 'secondary'
                : 'iconDisabled'
            }
          />
        </DsStack>
        <DsTypography
          color={
            disabled
              ? 'var(--ds-colour-typoDisabled)'
              : 'var(--ds-colour-typoPrimary)'
          }
          variant={'bodyBoldMedium'}
          sx={{
            m: 'var(--ds-spacing-zero) !important',
            px: 'var(--ds-spacing-glacial)'
          }}
        >
          {selectedOption()}
        </DsTypography>
        <DsStack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            '&.MuiStack-root': {
              m: 'var(--ds-spacing-zero) !important'
            },
            borderRadius: '50%',
            cursor:
              hasPrevNextOption().hasNext && !disabled
                ? 'pointer'
                : 'not-allowed',
            width: 'var(--ds-spacing-cool)',
            height: 'var(--ds-spacing-cool)',
            '&:hover': {
              backgroundColor:
                hasPrevNextOption().hasNext && !disabled
                  ? 'var(--ds-colour-stateSelectedPrimaryHover)'
                  : ''
            }
          }}
          onClick={() => handleCounterChange('next')}
        >
          <DsRemixIcon
            className='ri-add-line'
            fontSize='small'
            color={
              hasPrevNextOption().hasNext && !disabled
                ? 'secondary'
                : 'iconDisabled'
            }
          />
        </DsStack>
      </DsStack>
    </DsStack>
  )
}

export default CounterCard
